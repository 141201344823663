<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton, { Icon } from "@smui/icon-button";
  import Tooltip, { Wrapper, Content } from "@smui/tooltip";
  import CircularProgress from "@smui/circular-progress";
  import Checkbox from "@smui/checkbox";

  import DTPriceCurrencyCell from "../common/DTPriceCurrencyCell.svelte";

  import { mdiPencilBoxOutline, mdiDelete, mdiAlert, mdiAlertCircle, mdiCheckCircleOutline } from "@mdi/js";

  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import { createEventDispatcher } from "svelte";

  import { countryList } from "../../../stores/AppConfig.js";
  import { authenticatedUser } from "../../../stores/AppStatus";
  import { nvl, getFieldErrMsg, existsError, existErrors } from "../Upload/Upload.js";
  import type { QuoteSheetsItems } from "../../../types/QuoteSheets";

  // Items
  export let items: QuoteSheetsItems[] = [];
  let filteredItems: QuoteSheetsItems[] = [];

  // checkbox
  let checked = false;
  let indeterminate = false;
  export let checkboxItems = [];

  export let screenMode;

  //
  export let canUpdate = false;
  export let canDelete = false;

  // Flags
  export let dataValid = false;
  export let loadingData = false;

  // Filters
  export let tpnEnSearch;
  export let supplierSearch;
  export let countrySearch;
  export let groupSearch;
  export let productSearch;
  export let buyerSearchArray = [];
  export let statusSearchErrorsArray = [];

  const dispatch = createEventDispatcher();

  const isReadOnly = $authenticatedUser.isReadOnly;

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  // Tooltip position fix
  let tooltipXpos;
  let tooltipYpos;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  //Errors existing
  $: errorsWarningsInItems = filteredItems.some((item) => existErrors(item, "E") || existErrors(item, "W"));

  //checkbox select all
  function toggleSelectAll() {
    if (checked) {
      // Deselect all items
      filteredItems.forEach((item) => {
        item.selected = false;
      });
      checkboxItems.length = 0;
    } else {
      // Select all items
      filteredItems.forEach((item) => {
        if (screenMode === "upload" || item.itemPrivilage) {
          item.selected = true;
        }
      });
    }
  }

  // Update checkboxItems with selected item IDs
  $: checkboxItems = filteredItems.filter((item) => item.selected).map((item) => item.id);
  $: selectableItems = filteredItems.filter((item) => screenMode === "upload" || item.itemPrivilage);

  // Manage checked and indeterminate states
  $: {
    if (selectableItems.length === 0) {
      checked = false;
      indeterminate = false;
    } else {
      checked = selectableItems.every((item) => item.selected);
      indeterminate = !checked && selectableItems.some((item) => item.selected);
    }
  }

  $: console.log({ checkboxItems });
  $: console.log({ filteredItems });

  // Filter
  $: filteredItems = items?.filter((item) => {
    const ceTpnMatch = (item.productId ?? "").toString().includes(tpnEnSearch);
    const productDescMatch = (item.productDesc ?? "").toLowerCase().includes(productSearch?.toLowerCase() ?? "");
    // Search for a match in supplierId OR supplierName
    const supplierMatch =
      (item.supplierId ?? "")
        .toString()
        .toLowerCase()
        .includes(supplierSearch.toLowerCase() ?? "") || (item.supplierName ?? "").toLowerCase().includes(supplierSearch.toLowerCase() ?? "");
    const groupMatch = (item.sectionDesc ?? "").toLowerCase().includes(groupSearch.toLowerCase() ?? "");

    // Country Filter
    const countryMatch =
      countrySearch.length > 0
        ? countrySearch.some(
            (country) =>
              (country.id === "cz" && ((item.priceCz && item.priceCz > 0) || (item.pricePromoCz && item.pricePromoCz > 0))) ||
              (country.id === "sk" && ((item.priceSk && item.priceSk > 0) || (item.pricePromoSk && item.pricePromoSk > 0))) ||
              (country.id === "hu" && ((item.priceHu && item.priceHu > 0) || (item.pricePromoHu && item.pricePromoHu > 0))),
          )
        : true;

    // Check for any errors or warnings
    let errorsMatch = true;
    if (statusSearchErrorsArray.length > 0) {
      const errorsExist = item.errors?.some((errorItem) => statusSearchErrorsArray.some((searchError) => searchError.message === errorItem.message));
      const warningsExist = item.warnings?.some((warningItem) =>
        statusSearchErrorsArray.some((searchError) => searchError.message === warningItem.message),
      );
      errorsMatch = errorsExist || warningsExist;
    }

    // Check for valid type
    const includesValidType = statusSearchErrorsArray.some(({ type }) => type === "V");

    // Include items with no errors or warnings
    if (includesValidType) {
      errorsMatch = errorsMatch || ((!item.errors || item.errors.length === 0) && (!item.warnings || item.warnings.length === 0));
    }

      // Buyer search
      const buyerMatch = buyerSearchArray.length > 0 
      ? buyerSearchArray.some(buyer => 
          item.buyerDetails.some(detail => detail.uuid === buyer.id)
        )
      : true;

    return countryMatch && ceTpnMatch && productDescMatch && groupMatch && supplierMatch && errorsMatch && buyerMatch;
  });

// Function to get unique buyers
function getUniqueBuyers(item) { 
  // Check if buyerDetails array exists and is not empty
  if (!item.buyerDetails || item.buyerDetails.length === 0) {
    return [];
  }

  // Use Map to get unique buyers by uuid
  const uniqueBuyersMap = new Map();
  item.buyerDetails.forEach((buyer) => {
    if (!uniqueBuyersMap.has(buyer.uuid)) {
      uniqueBuyersMap.set(buyer.uuid, buyer);
    }
  });

  // Sort by full name (lastName + firstName)
  return Array.from(uniqueBuyersMap.values()).sort((a, b) => {
    const nameA = `${a.lastName} ${a.firstName}`.toLowerCase();
    const nameB = `${b.lastName} ${b.firstName}`.toLowerCase();
    return nameA.localeCompare(nameB);
  });
}
</script>

<DataTable table$aria-label="QS Data Table" class="w-100 mt-2 report">
  <Head>
    <Row>
      {#if !isReadOnly}
        <Cell columnId="actions" class="datatable-header pl-0 pr-0 sticky-col right-splitter">
          <!-- Delete Button with Checkbox -->
          {#if canDelete && slice.length != 0}
            <Checkbox bind:checked bind:indeterminate disabled={selectableItems.length === 0} on:click={toggleSelectAll} />
            <div class="spacer"></div>
            <!-- Spacer instead of edit -->
            <IconButton
              title={$_("delete_all")}
              size="button"
              on:click={() => checkboxItems.length > 0 && dispatch("deleteSelectedRows", checkboxItems)}
              disabled={checkboxItems.length === 0 || !canDelete}
            >
              <Icon tag="svg" viewBox="0 0 24 24">
                <path fill="currentColor" d={mdiDelete} />
              </Icon>
            </IconButton>
          {/if}
        </Cell>
        {#if errorsWarningsInItems && screenMode == "upload"}
          <Cell columnId="error" class="datatable-header right-splitter">
            <Label>{$_("error")}</Label>
          </Cell>
        {/if}
      {/if}
      <Cell
        columnId="tpnCE"
        class="datatable-header sticky-col qs {slice?.length > 0 && canUpdate && canDelete
          ? 'left-3x'
          : slice?.length > 0 && (canUpdate || canDelete)
            ? 'left-1x'
            : ''} right-splitter"
      >
        <Label>{$_("tpn_ce")}</Label>
      </Cell>
      <Cell columnId="supplierName" class="datatable-header right-splitter">
        <Label>{$_("supplier")}</Label>
      </Cell>
      <Cell columnId="buyer" class="datatable-header right-splitter">
        <Label>{$_("buyer")}</Label>
      </Cell>
      <Cell columnId="sectionDesc" class="datatable-header right-splitter">
        <Label>{$_("group")}</Label>
      </Cell>
      <Cell columnId="productNameEn" class="datatable-header right-splitter">
        <Label>{$_("product_name_en")}</Label>
      </Cell>
      <Cell columnId="countryOfOrigin" class="datatable-header right-splitter wrap-spaces">
        <Label>{$_("country_of_origin")}</Label>
      </Cell>
      <Cell columnId="variety" class="datatable-header right-splitter">
        <Label>{$_("variety")}</Label>
      </Cell>
      <Cell columnId="perKgPiece" class="datatable-header text-center right-splitter">
        <Label>{$_("units")}</Label>
      </Cell>
      <Cell columnId="cartonLogistics" class="datatable-header text-center right-splitter">
        <Label>{$_("logistics")}</Label>
      </Cell>
      <Cell columnId="volume" class="datatable-header right-splitter">
        <Label>{$_("volume")}</Label>
      </Cell>
      <Cell colspan="1" columnId="czPriceColumns" class="datatable-header text-center right-splitter wrap-spaces">
        <Label>{$_("price_promo_cz")}</Label>
      </Cell>
      <Cell colspan="1" columnId="skPriceColumns" class="datatable-header text-center right-splitter wrap-spaces">
        <Label>{$_("price_promo_sk")}</Label>
      </Cell>
      <Cell colspan="1" columnId="huPriceColumns" class="datatable-header text-center right-splitter wrap-spaces">
        <Label>{$_("price_promo_hu")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataValid}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_week_data_or_import")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as item ([item.id, item.fileName, item.fileRowNo].join("|"))}
        <Row>
          {#if !isReadOnly}
            <Cell class="pl-0 pr-0 sticky-col right-splitter-sub">
              <Flex direction="row" justify="start">
                <!-- Checkbox -->
                {#if canDelete}
                  <Checkbox bind:checked={item.selected} disabled={screenMode !== "upload" && !item.itemPrivilage} />
                {/if}
                <!-- Edit -->
                {#if canUpdate}
                  {#if screenMode != "upload" || (screenMode == "upload" && !item.errors.map((x) => x.message).includes("duplicate_product_in_file"))}
                    <div title={$_("edit")}>
                      <IconButton
                        size="button"
                        class={!item.itemPrivilage ||
                        (existsError(item, "productId", "E") &&
                          getFieldErrMsg(item, "productId", "E") == "product_not_found_or_missing_product_supplier_link") ||
                        !canUpdate
                          ? ""
                          : "tescoblue-text opacity-1"}
                        disabled={!item.itemPrivilage ||
                          (existsError(item, "productId", "E") &&
                            getFieldErrMsg(item, "productId", "E") == "product_not_found_or_missing_product_supplier_link") ||
                          !canUpdate}
                        on:click={() => dispatch("editQuoteSheetRow", item)}
                      >
                        <Icon
                          tag="svg"
                          viewBox="0 0 24 24"
                          class={!item.itemPrivilage ||
                          (existsError(item, "productId", "E") &&
                            getFieldErrMsg(item, "productId", "E") == "product_not_found_or_missing_product_supplier_link") ||
                          !canUpdate
                            ? ""
                            : "tescoblue-text"}
                        >
                          <path fill="currentColor" d={mdiPencilBoxOutline} />
                        </Icon>
                      </IconButton>
                    </div>
                  {/if}
                {/if}
                <!-- Delete -->
                {#if canDelete}
                  <div title={$_("delete")}>
                    <IconButton
                      size="button"
                      class="opacity-1"
                      disabled={screenMode !== "upload" && !item.itemPrivilage}
                      on:click={() => dispatch("deleteQuoteSheetRow", item.id)}
                    >
                      <Icon tag="svg" viewBox="0 0 24 24" class={screenMode !== "upload" && !item.itemPrivilage ? "" : "tescoblue-text"}>
                        <path fill="currentColor" d={mdiDelete} />
                      </Icon>
                    </IconButton>
                  </div>
                {/if}
                <!-- Success/Error Icon -->
                {#if item.warnings && item.warnings.length > 0 && item.warnings[0].type === "D" && screenMode != "upload"}
                  <div title={item.warnings[0].message === "OK" ? $_("delete_ok") : $_(item.warnings[0].message)}>
                    <IconButton size="button" class="opacity-1" disabled>
                      <Icon tag="svg" viewBox="0 0 24 24" class={item.warnings[0].message === "OK" ? "green-text" : "orange-text"}>
                        <path fill="currentColor" d={item.warnings[0].message === "OK" ? mdiCheckCircleOutline : mdiAlert} />
                      </Icon>
                    </IconButton>
                  </div>
                {:else if !item.itemPrivilage && screenMode === "edit"}
                  <div title={$_("item_privilage_missing")}>
                    <IconButton size="button" class="opacity-1" disabled>
                      <Icon tag="svg" viewBox="0 0 24 24" class="orange-text">
                        <path fill="currentColor" d={mdiAlert} />
                      </Icon>
                    </IconButton>
                  </div>
                {/if}
              </Flex>
            </Cell>
            <!-- Errors, Warnings-->
            {#if errorsWarningsInItems && screenMode == "upload"}
              <Cell class="right-splitter-sub" style="overflow: visible">
                <Flex direction="row">
                  {#if existErrors(item, "E")}
                    <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                      <svg
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        role="button"
                        tabindex="0"
                        aria-label="Show errors"
                        on:mouseenter={(event) => {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }}
                      >
                        <path fill="red" d={mdiAlertCircle} />
                      </svg>
                      <Tooltip
                        style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                        surface$style="max-width: max-content"
                      >
                        <Content style="max-width: fit-content;">
                          {@html item.errors ? item.errors.map((x) => $_(x.message)).join("<br>") : null}
                        </Content>
                      </Tooltip>
                    </Wrapper>
                  {/if}
                  {#if existErrors(item, "W")}
                    <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                      <svg
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        role="button"
                        tabindex="0"
                        aria-label="Show warnings"
                        on:mouseenter={(event) => {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }}
                      >
                        <path fill="orange" d={mdiAlert} />
                      </svg>
                      <Tooltip
                        style="position: fixed !important; left: calc(var(--tooltip-left, 10rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                        surface$style="max-width: max-content"
                      >
                        <Content style="max-width: fit-content;">
                          {@html item.warnings ? item.warnings.map((x) => $_(x.message)).join("<br>") : null}
                        </Content>
                      </Tooltip>
                    </Wrapper>
                  {/if}
                </Flex>
              </Cell>
            {/if}
          {/if}
          <!-- Product ID -->
          <Cell class="sticky-col qs {canUpdate && canDelete ? 'left-2x' : canUpdate || canDelete ? 'left-1x' : ''} right-splitter-sub text-center">
            {#if screenMode == "upload" && existsError(item, "productId", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "productId", "E"))}>{item.productId}</span>
            {:else}
              <span>{item.productId}</span>
            {/if}
          </Cell>

          <!-- Supplier Name -->
          <Cell class="right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "supplierId", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "supplierId", "E"))}>{item.supplierId}</span>
            {:else}
              <span>{item.supplierName}</span>
            {/if}
          </Cell>

          <!-- Buyer -->
          <Cell class="right-splitter-sub">
            {#if getUniqueBuyers(item).length > 0}
              {#if getUniqueBuyers(item).length === 1}
                <!-- Display only one buyer -->
                <span>{getUniqueBuyers(item)[0].lastName} {getUniqueBuyers(item)[0].firstName}</span>
              {:else}
                <!-- Multiple buyers with tooltip -->
                <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px;">
                  <span>{getUniqueBuyers(item)[0].lastName} {getUniqueBuyers(item)[0].firstName} ...</span>
                  <!-- Tooltip on mouse position -->
                  <Tooltip
                    style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                    surface$style="max-width: max-content"
                  >
                    <Content style="max-width: fit-content;">
                      {#each getUniqueBuyers(item) as buyer}
                        <div>{buyer.lastName} {buyer.firstName}</div>
                      {/each}
                    </Content>
                  </Tooltip>
                </Wrapper>
              {/if}
            {:else}
              <span>-</span> <!-- When there are no buyers -->
            {/if}
          </Cell>

          <!-- Group -->
          <Cell class="right-splitter-sub">{nvl(item.sectionDesc)}</Cell>

          <!-- Product Description-->
          <Cell class="right-splitter-sub">{nvl(item.productDesc)}</Cell>

          <!-- Country of origin -->
          <Cell class="text-left right-splitter-sub">
            {#if screenMode == "upload" && existErrors(item, "E") && item.errors.map((x) => x.field).includes("countryOfOrigin")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "countryOfOrigin", "E"))}
                >{item.countryOfOrigin && item.countryOfOrigin != null
                  ? [...$countryList].filter((x) => x.code == item.countryOfOrigin) &&
                    [...$countryList].filter((x) => x.code == item.countryOfOrigin).length > 0
                    ? [...$countryList].filter((x) => x.code == item.countryOfOrigin)[0].description
                    : item.countryOfOrigin
                  : ""}</span
              >
            {:else}
              <span
                >{item.countryOfOrigin && item.countryOfOrigin != null
                  ? [...$countryList].filter((x) => x.code == item.countryOfOrigin) &&
                    [...$countryList].filter((x) => x.code == item.countryOfOrigin).length > 0
                    ? item.countryOfOrigin + " - " + [...$countryList].filter((x) => x.code == item.countryOfOrigin)[0].description
                    : item.countryOfOrigin
                  : ""}</span
              >
            {/if}
          </Cell>

          <!-- Variety-->
          <Cell class="right-splitter-sub">{nvl(item.variety)}</Cell>

          <!-- Units -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existErrors(item, "E") && item.errors.map((x) => x.field).includes("units")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "units", "E"))}>{$_(nvl(item.units, ""))}</span>
            {:else}
              <span>{$_(nvl(item.units, ""))}</span>
            {/if}
          </Cell>
          <!-- Logistics -->
          <Cell class="text-center right-splitter-sub">
            {#if nvl(item.cartonLogistics, "") == nvl(item.productCartonLogistics, "")}
              <span>{nvl(item.cartonLogistics, "-")}</span>
            {:else}
              <span
                class="orange-text"
                title={$_("carton_logistics_different_from_product_definition_extended", { values: { productValue: item.productCartonLogistics } })}
                >{nvl(item.cartonLogistics, "-")}</span
              >
            {/if}
            /
            {#if nvl(item.palletLogistics, "") == nvl(item.productPalletLogistics, "")}
              <span>{nvl(item.palletLogistics, "-")}</span>
            {:else}
              <span
                class="orange-text"
                title={$_("pallet_logistics_different_from_product_definition_extended", { values: { productValue: item.productPalletLogistics } })}
                >{nvl(item.palletLogistics, "-")}</span
              >
            {/if}
          </Cell>

          <!-- Volume -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "volume", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "volume", "E"))}>{nvl(item.volume)}</span>
            {:else}
              <span>{nvl(item.volume)}</span>
            {/if}
          </Cell>
          <!-- CZ prices and currency-->
          <DTPriceCurrencyCell
            errors={item.errors}
            warnings={item.warnings}
            {screenMode}
            country={"Cz"}
            currency={item.currencyCz}
            productCurrency={item.productCurrencyCz}
            price={item.priceCz}
            pricePromo={item.pricePromoCz}
          />
          <!-- SK prices and currency-->
          <DTPriceCurrencyCell
            errors={item.errors}
            warnings={item.warnings}
            {screenMode}
            country={"Sk"}
            currency={item.currencySk}
            productCurrency={item.productCurrencySk}
            price={item.priceSk}
            pricePromo={item.pricePromoSk}
          />
          <!-- HU prices and currency-->
          <DTPriceCurrencyCell
            errors={item.errors}
            warnings={item.warnings}
            {screenMode}
            country={"Hu"}
            currency={item.currencyHu}
            productCurrency={item.productCurrencyHu}
            price={item.priceHu}
            pricePromo={item.pricePromoHu}
          />
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}
      >first_page</IconButton
    >
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}
      >chevron_left</IconButton
    >
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}
      >chevron_right</IconButton
    >
    <IconButton
      class="material-icons"
      action="last-page"
      title="Last page"
      on:click={() => (currentPage = lastPage)}
      disabled={currentPage === lastPage}>last_page</IconButton
    >
  </Pagination>
</DataTable>

<style>
  .spacer {
    display: inline-block;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
</style>
