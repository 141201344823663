import Api from "../../../services/Api";
import { checkQuoteSheetItems as apiCheckQuoteSheetItems } from "../../../api/QuoteSheet";

export const loadQuoteSheet = async (year, week, supplierId, productId, countryOfOrigin, variety, cartonLogistics, palletLogistics) => {
  try {
    const params = new URLSearchParams();
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (supplierId) params.append('supplierId', supplierId);
    if (productId) params.append('productId', productId);
    if (countryOfOrigin) params.append('countryOfOrigin', countryOfOrigin);
    if (variety) params.append('variety', variety);
    if (cartonLogistics) params.append('cartonLogistics', cartonLogistics);
    if (palletLogistics) params.append('palletLogistics', palletLogistics);

    const response = await Api.get("/quoteSheet/", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

export const checkQuoteSheetItems = async (data) => {
  console.log("checkQuoteSheetItems()", { data });
  var response = await apiCheckQuoteSheetItems(data)
    .then((res) => {
      console.log({ res });
      var quouteSheetList = [];
      for (let quoteSheet of res) {
        // console.log({product});
        quouteSheetList.push({ ...quoteSheet });
      }

      if (quouteSheetList.length == 0) {
        console.log('loadQuoteSheet().empty');
      } else {
        return quouteSheetList;
      }
    })
    .catch((error) => {
      console.log(error);
      if (error?.cause?.response?.status == 401) { throw (error) }
    })
  return response;
};
