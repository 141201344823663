import Api from "../services/Api";

// Method to get report Delivery Schedule
export const getDeliveryScheduleHist = async (year, week, supplierId, warehouseCode, buyer) => {
    try {
      const params = new URLSearchParams();
      params.append('year', year);
      params.append('week', week);
      if (warehouseCode && warehouseCode?.length == 2) {
        params.append('country', warehouseCode);
      } else if (warehouseCode) {
        params.append('warehouseCode', warehouseCode);
      }
      if (supplierId) params.append('supplierId', supplierId);
      if (buyer) params.append('buyer', buyer);

      const response = await Api.get("/deliverySchedule/hist", params);
      console.log({response});
      if (response.status) {
        return response.payload;
      } else {
        console.error(response.payload ? response.payload : response.message);
        throw new Error(response.payload ? response.payload : response.message);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error.message, {cause: error});
    }
};


// Method to get list of suppliers involved in Delivery Schedule for given week
export const getDeliveryScheduleSuppliers = async (year, week, notified, confirmed) => {
  try {
    const params = new URLSearchParams();
    params.append('year', year);
    params.append('week', week);
    if (notified) params.append('notified', notified);
    if (confirmed) params.append('confirmed', confirmed);

    const response = await Api.get("/deliverySchedule/suppliers", params);
    console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, {cause: error});
  }
};


