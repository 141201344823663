<script>
  import { rejectPurchaseReasons, countryList } from "../../../stores/AppConfig";

  import Dialog, { Title, Header, Content, Actions, InitialFocus } from '@smui/dialog';
  import DataTable, { Body, Row, Cell } from "@smui/data-table";
  import Button from '@smui/button';
  import IconButton, { Icon } from "@smui/icon-button";
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';

  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import { Label } from '@smui/common';
  import CircularProgress from '@smui/circular-progress';
  import Autocomplete from "../../extra/Autocomplete.svelte";

  import DTInputCell from "../common/DTInputCell.svelte";

  import { formatVolume, isNumber } from '../lib/Functions';

  import { _ } from 'svelte-i18n';
  import Flex from 'svelte-flex';

  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();


  export let open = false;
  export let insideDialog = false;
  export let item = {};
  export let product = {};

  let working = false;
  let dataInvalid = false;
  let listEl;
  let countryEl;
  let menuWidth;
  let menuBottom;


  let countryLower = '';
  let countryUpper = '';
  let countryMix = '';

  let cartonOrigValue;
  let palletOrigValue;

  let cartonFieldError;
  let palletFieldError;
  let priceFieldError;
  let pricePromoFieldError;

  let countryOfOriginList = [];
  let countryOfOriginResultList = [];
  let selectedCountryOfOrigin = null;
  let countryOfOriginFound = false;

  let applyChangeToProduct = false;
  let applyChangeToOthers = false;
  let applyChangeToOthersDisabledFlag = false;

  countryOfOriginList = [...$countryList]
      .filter((e) => e.active)
      .map((x) => {
          return { code: x.code, name: x.description, text: x.code + " - " + x.description };
      });

  const dialogConfirmed = (() => {
    console.log("confirmed", {item})
    dispatch('confirmed', {...item, "applyChangeToProduct": applyChangeToProduct, "applyChangeToOthers": applyChangeToOthers});
    working = true;
  })

  function validateField (val, conditions) {
    if (!conditions || !Array.isArray(conditions)) return '';

    console.log("validateField()", val, conditions.join(","));

    let ret = '';
    conditions.forEach((c) => {
        if (!ret) {
            switch (c) {
                case 'nonEmpty':
                    console.log('nonEmpty');
                    if (typeof val === "undefined" || !val) ret = 'field_is_required';
                    break;
                case 'number':
                    console.log('number');
                    if (val && !isNumber(val)) ret = 'value_must_be_number';
                    break;
                case 'integer':
                    console.log('integer');
                    if (val && !Number.isInteger(Number(val))) ret = 'value_must_be_integer';
                    break;
                case 'gtZero':
                    console.log('gtZero');
                    if (val && val <= 0) ret = 'value_cant_be_negative';
                    if (val && val == 0) ret = 'value_cant_be_zero';
                    break
            }
        }
    })

    console.log("END", ret);
    return (ret);
  }

    async function searchCountryOfOrigin(input) {
        console.log("====================== searchCountryOfOrigin =========================");
        if (input.length < 0) {
            return [];
        }
        try {
            let results = [...countryOfOriginList].filter((x) => x.name.toUpperCase().includes(input.toUpperCase()) || x.code.toUpperCase() == input.toUpperCase());
            countryOfOriginResultList = results;
            return results.map((i) => i.text);
        } catch (error) {
            console.error("Error fetching CountryOfOrigin autocomplete data:", error);
            return [];
        }
    }

    async function countryOfOriginSelected(option) {
        console.log("====================== countryOfOriginSelected =========================");
        let selectedCountryOfOriginRow = [...countryOfOriginResultList].find((i) => i.text === option.detail);
        countryOfOriginFound = true;
        item.countryOfOrigin = selectedCountryOfOriginRow.code;
        selectedCountryOfOrigin = selectedCountryOfOriginRow.code;
        //selectedCountryOfOrigin = selectedCountryOfOrigin;
        console.log({ selectedCountryOfOrigin });
    }

    function countryOfOriginClear() {
        console.log("====================== countryOfOriginClear =========================");
        countryOfOriginFound = false;
        item.countryOfOrigin = null;
        selectedCountryOfOrigin = null;
    }

    function countryOfOriginBlur(object) {
        console.log("====================== countryOfOriginBlur =========================");
        console.log({ selectedCountryOfOrigin });
        if (typeof selectedCountryOfOrigin === "undefined" || !selectedCountryOfOrigin || selectedCountryOfOrigin == null || selectedCountryOfOrigin == "") {
            countryOfOriginClear();
        }
    }


  $: if (item && item.country && countryLower != item.country.toLowerCase()) {
    countryLower = item.country?.toLowerCase();
    countryUpper = item.country?.toUpperCase();
    countryMix = countryUpper[0]+countryLower[1];
  }

  $: if (item && item.countryOfOrigin && !selectedCountryOfOrigin) {
        selectedCountryOfOrigin =
            item?.countryOfOrigin +
            ([...countryOfOriginList].filter((e) => e.code == item?.countryOfOrigin).length > 0
                ? " - " + [...countryOfOriginList].filter((e) => e.code == item?.countryOfOrigin)[0].name
                : "");
  }

  $: if (item && !cartonOrigValue) {
    cartonOrigValue = item.cartonLogistics;
    palletOrigValue = item.palletLogistics;
  }

  $: {cartonFieldError = (item ? validateField(item.cartonLogistics, ["nonEmpty", "number", "integer", "gtZero"]) : '');
      if (cartonFieldError=='') {item.cartonLogistics = Number(item.cartonLogistics);}  
    }
  $: {palletFieldError = (item ? validateField(item.palletLogistics, ["nonEmpty", "number", "integer", "gtZero"]) : '');
      if (palletFieldError=='') {item.palletLogistics = Number(item.palletLogistics);}
    }
  $: {let cntr = item.country?.toUpperCase()[0]+item.country?.toLowerCase()[1]; 
      priceFieldError = (item ? validateField(item['price'+cntr], ["nonEmpty", "number", "gtZero"]) : ''); 
      if (priceFieldError=='') {item['price'+cntr] = Number(item['price'+cntr]);}  
    }
  $: {let cntr = item.country?.toUpperCase()[0]+item.country?.toLowerCase()[1];
      pricePromoFieldError = (item ? validateField(item['pricePromo'+cntr], ["number", "gtZero"]) : '');
     if (pricePromoFieldError=='') {item['pricePromo'+cntr] = Number(item['pricePromo'+cntr]);}  
    }
  $: countryOfOriginFieldError = '';

  $: dataInvalid = (cartonFieldError || palletFieldError || priceFieldError || pricePromoFieldError || countryOfOriginFieldError);

  $: cartonFieldWarning = (item.cartonLogistics != product.cartonLogistics || (item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics));
  $: palletFieldWarning = (item.palletLogistics != product.palletLogistics || (item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics));

  $: applyChangeToOthersDisabledFlag = (cartonOrigValue != item.cartonLogistics || palletOrigValue != item.palletLogistics);
  $: applyChangeToOthers = (applyChangeToOthersDisabledFlag && cartonOrigValue ? true : applyChangeToOthers)

  $:{
    console.log("---- ----");
    console.log({open});
    console.log({item});
    console.log({countryMix});
    console.log("price"+countryMix, item['price'+countryMix]);
    console.log("cartonLogistics", item.cartonLogistics, item.lwCartonLogistics, product.cartonLogistics);
    console.log("palletLogistics", item.palletLogistics, item.lwPalletLogistics, product.palletLogistics);
    console.log({cartonOrigValue}, {palletOrigValue});
    console.log({dataInvalid});
  }

</script>

<Dialog
    bind:open={open}
    slot="{insideDialog ? 'over' : ''}"
    class="pa-2"
    surface$style="min-height:300px; min-width: 850px;"
>
<Header>
    <Title id="over-fullscreen-confirmation-title" class="tescoblue white-text">
    {$_('qs_update')}
    </Title>
</Header>
<Content id="over-fullscreen-confirmation-content" class="pb-0" style="display:flex;">
    <DataTable class="w-100 input-form no-border pa-2">
        <Body>
            <Row class="small">
                <Cell class="no-border"></Cell>
                <Cell class="no-border tescoblue-text font-weight-bold text-center">{$_('original')}</Cell>
                <Cell class="no-border tescoblue-text font-weight-bold text-center" colspan="4">{$_('current')}</Cell>
            </Row>
            <Row>
                <Cell class="form-label">{$_("variety")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item.origVariety}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />
                <td colspan="3">
                    <Textfield
                        variant="outlined"
                        bind:value={item.variety}
                        updateInvalide={false}
                        input$maxlength={100}
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        class="w-100 tescoblue-text edit-cell-border"
                    >
                    </Textfield>
                </td>
            </Row>
            <Row>
                <Cell class="form-label">{$_("country_of_origin")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item.origCountryOfOrigin}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />

<!--                <DTInputCell
                    colspan="2"
                    bind:value={item.countryOfOrigin}
                    editable={true}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text white"
                />-->
                <td colspan="3">
                    <Autocomplete
                        class="w-100 tescoblue-text edit-cell-border"
                        combobox
                        search="{(input) => searchCountryOfOrigin(input)}"
                        showMenuWithNoInput={false}
                        minInputSizeToShowMenu="2"
                        selectOnExactMatch={true}
                        bind:value={selectedCountryOfOrigin}
                        bind:this={countryEl}
                        menu$class="mdc-menu-surface--fixed with-parameter__menu"
                        style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                        on:focusin="{() => {
                            //console.log('In Focus', {countryEl});
                            menuWidth = countryEl?.getElement()?.clientWidth;
                            menuBottom = countryEl?.getElement()?.getBoundingClientRect()?.bottom;
                        }}"
                        on:click="{() => {
                            //console.log('On Click', {countryEl});
                            menuWidth = countryEl?.getElement()?.clientWidth;
                        }}"
                        menuBottom="countryEl?.getElement()?.getBoundingClientRect()?.bottom;"
                        on:blur="{(event) => {
                            countryOfOriginBlur(event);
                        }}"
                        on:SMUIAutocomplete:selected="{(option) => {
                            countryOfOriginSelected(option);
                        }}"
                    >
                        <Textfield
                            type="text"
                            bind:value={selectedCountryOfOrigin}
                            withTrailingIcon={countryOfOriginFieldError}
                            invalid={countryOfOriginFieldError}
                            updateInvalide={false}
                            input$maxlength={5}
                            input$autocomplete="Nothing"
                            input$emptyValueUndefined
                            input$placeholder={$_("enter_min_chars", { values: { chars: 2 } })}
                            required
                            style="min-width: 500px;"
                            class="w-100 tescoblue-text edit-cell-border pr-1"
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if countryOfOriginFieldError}
                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    </Autocomplete>
                </td>
            </Row>
            {#if countryOfOriginFieldError}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell colspan="5" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(countryOfOriginFieldError)}
                        </div>
                    </Cell>
                </Row>
            {/if}
            <Row>
                <Cell class="form-label">{$_("carton_logistics")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item.origCartonLogistics}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />
                <td colspan="1">
                    <Textfield
                        variant="outlined"
                        bind:value={item.cartonLogistics}
                        withTrailingIcon={cartonFieldError}
                        invalid={cartonFieldError}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="cartonLogistics"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        required
                        class="w-100 tescoblue-text edit-cell-border pr-1"
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if cartonFieldError}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                            {#if cartonFieldWarning}
                                <Icon class="material-icons orange-text mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
                <Cell class="no-border tescoblue-text pr-0 pl-0" style="width: 1rem;">
                    <DataTable class="report no-border tescoblue-text w-100">
                        <Body>
                            <Row>
                                <Cell class="tescoblue-text white font-weight-bold text-right pr-1" >{$_('product')}:</Cell>
                            </Row>
                            <Row>
                                <Cell class="tescoblue-text white font-weight-bold text-right pr-1" title="{$_('last_week')}">{$_('lw')}:</Cell>
                            </Row>
                        </Body>
                    </DataTable>
                </Cell>
                <Cell class="no-border tescoblue-text  pr-0 pl-0">
                    <DataTable class="report no-border tescoblue-text">
                        <Body>
                            <Row>
                                <Cell class="tescoblue-text {item.cartonLogistics != product.cartonLogistics ? 'yellow' : 'white'} text-left pl-1 pr-1">{product.cartonLogistics}</Cell>
                            </Row>
                            <Row>
                                <Cell class="tescoblue-text {item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics ? 'yellow' : 'white'} text-left pl-1">{item.lwCartonLogistics ? item.lwCartonLogistics : ''}</Cell>
                            </Row>
                        </Body>
                    </DataTable>
                </Cell>
            </Row>
            {#if cartonFieldError || cartonFieldWarning}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell colspan="5" style="padding: 0">
                        {#if cartonFieldError}
                            <div class="red-text fs-08rem">
                                {$_(cartonFieldError)}
                            </div>
                        {:else}
                            <div class="orange-text fs-08rem">
                                {$_('carton_logistics_differs')}
                            </div>
                        {/if}
                    </Cell>
                </Row>
            {/if}

            <Row>
                <Cell class="form-label">{$_("pallet_logistics")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item.origPalletLogistics}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />
                <td colspan="1">
                    <Textfield
                        variant="outlined"
                        bind:value={item.palletLogistics}
                        withTrailingIcon={cartonFieldError}
                        invalid={cartonFieldError}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="palletLogistics"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        required
                        class="w-100 tescoblue-text edit-cell-border pr-1"
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if palletFieldError}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                            {#if palletFieldWarning}
                                <Icon class="material-icons orange-text mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
                <Cell class="no-border tescoblue-text pr-0 pl-0" style="width: 1rem;">
                    <DataTable class="report no-border tescoblue-text w-100">
                        <Body>
                            <Row>
                                <Cell class="tescoblue-text white font-weight-bold text-right pr-1">{$_('product')}:</Cell>
                            </Row>
                            <Row>
                                <Cell class="tescoblue-text white font-weight-bold text-right pr-1" title="{$_('last_week')}">{$_('lw')}:</Cell>
                            </Row>
                        </Body>
                    </DataTable>
                </Cell>
                <Cell class="no-border tescoblue-text  pr-0 pl-0">
                    <DataTable class="report no-border tescoblue-text">
                        <Body>
                            <Row>
                                <Cell class="tescoblue-text {item.palletLogistics != product.palletLogistics ? 'yellow' : 'white'} text-left pl-1 pr-1">{product.palletLogistics}</Cell>
                            </Row>
                            <Row>
                                <Cell class="tescoblue-text {item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics ? 'yellow' : 'white'} text-left pl-1">{item.lwPalletLogistics ? item.lwPalletLogistics : ''}</Cell>
                            </Row>
                        </Body>
                    </DataTable>
                </Cell>
            </Row>
            {#if palletFieldError || palletFieldWarning}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell colspan="5" style="padding: 0">
                        {#if palletFieldError}
                            <div class="red-text fs-08rem">
                                {$_(palletFieldError)}
                            </div>
                        {:else}
                            <div class="orange-text fs-08rem">
                                {$_('pallet_logistics_differs')}
                            </div>
                        {/if}
                    </Cell>
                </Row>
            {/if}
            <Row>
                <Cell class="form-label">{countryUpper+' '+$_("price")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item['origPrice'+countryMix]}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />
                <td>
                    <Textfield
                        variant="outlined"
                        bind:value={item['price'+countryMix]}
                        withTrailingIcon={priceFieldError}
                        invalid={priceFieldError}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="price"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        required
                        class="w-100 tescoblue-text edit-cell-border pr-1"
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if priceFieldError}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
                <DTInputCell
                    colspan="1"
                    value={item['currency'+countryMix]}
                    editable={false}
                    numeric={false}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4 text-center"
                />
                <Cell class="no-border"></Cell>
            </Row>
            {#if priceFieldError}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell colspan="5" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(priceFieldError)}
                        </div>
                    </Cell>
                </Row>
            {/if}
            <Row>
                <Cell class="form-label">{countryUpper+' '+$_("promo_price")}</Cell>
                <DTInputCell
                    colspan="1"
                    value={item['origPricePromo'+countryMix]}
                    editable={false}
                    numeric={true}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4"
                />
                <td>
                    <Textfield
                        variant="outlined"
                        bind:value={item['pricePromo'+countryMix]}
                        withTrailingIcon={pricePromoFieldError}
                        invalid={pricePromoFieldError}
                        updateInvalide={false}
                        input$maxlength={8}
                        input$autocomplete="pricePromo"
                        input$emptyValueUndefined
                        input$class="tescoblue-text fs-1rem"
                        class="w-100 tescoblue-text edit-cell-border pr-1"
                    >
                        <svelte:fragment slot="trailingIcon">
                            {#if pricePromoFieldError}
                                <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                            {/if}
                        </svelte:fragment>
                    </Textfield>
                </td>
                <DTInputCell
                    colspan="1"
                    value={item['currency'+countryMix]}
                    editable={false}
                    numeric={false}
                    style="width: 7rem;"
                    class="fs-1rem tescoblue-text grey lighten-4 text-center"
                />
                <Cell class="no-border"></Cell>
            </Row>
            {#if pricePromoFieldError}
                <Row class="helpertext__row">
                    <Cell></Cell>
                    <Cell></Cell>
                    <Cell colspan="5" style="padding: 0">
                        <div class="red-text fs-08rem">
                            {$_(pricePromoFieldError)}
                        </div>
                    </Cell>
                </Row>
            {/if}
        </Body>
    </DataTable>
</Content>
<div class="mdc-dialog__actions gap-1 pr-4 mr-4 pb-4">
    <Flex direction="row" align="between" class="w-100 gap-2" justify="end">
        <Flex direction="column" align="start" style="--mdc-checkbox-touch-target-size: 24px;">
            <Flex direction="row">
                <FormField>
                    <Checkbox bind:checked={applyChangeToProduct} />
                    <span slot="label" class="tescoblue-text">{$_('apply_to_product')}</span>
                </FormField>
            </Flex>
            <Flex direction="row">
                <FormField>
                    <Checkbox bind:checked={applyChangeToOthers} disabled={applyChangeToOthersDisabledFlag} />
                    <span slot="label" class="{applyChangeToOthersDisabledFlag ? 'grey-text' : 'tescoblue-text'}">{$_('apply_to_all_country_purch')}</span>
                </FormField>
            </Flex>
        </Flex>
        <Flex direction="row" class="gap-1">
            <Button
                action="close"
                disabled={working}
                class="{!working ? 'tescored tescored-text' : 'grey grey-text'} outlined"
                on:click="{() => {open = false; dispatch('cancelled')}}"
            >
                <Label>{$_('cancel')}</Label>
            </Button>
            <Button
                action="save"
                default
                defaultAction
                disabled="{working || dataInvalid}"
                class="{!(working || dataInvalid) ? 'tescoblue' : 'grey'} white-text"
                on:click="{dialogConfirmed}">
            <Label>
                <Flex dirction="row" class="gap-05 pr-1">
                {#if working}
                    <CircularProgress class="white-text" style="height: 18px; width: 18px;" indeterminate />
                {/if}
                {$_('ok')}
                </Flex>
            </Label>
            </Button>
        </Flex>
    </Flex>
</div>
</Dialog>
