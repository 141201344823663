
import { _ } from 'svelte-i18n';
import { loadSuppliers, loadSupplierProducts } from '../Load/Supplier.js';
import { loadQuoteSheet, checkQuoteSheetItems } from '../Load/QuoteSheet.js';

import { get as getStoreVal } from 'svelte/store';
import { countryList } from '../../../stores/AppConfig.js';
import { existsError, clearError, getFieldErrMsg, updateErrors } from '../Upload/Upload.js'

let countryOfOriginList = [];

let debugEnabled = false;

function isNumber(value) {
	if (value == null) return false;
	else if (typeof value === 'number') return true;
	else return !isNaN(value);
}


export function validateSupplierStatus(r) {
	debugEnabled && console.log('================= validateSupplierStatus ==============');
	const errorField = 'supplierId';
	var err = null;
	// Start evaluation
	if (!r.supplierStatus) {
		err = { priority: 2, message: 'supplier_is_inactive', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}


export function validateProductStatus(r, prod) {
	debugEnabled && console.log('================= validateProductStatus ==============');
	debugEnabled && console.log(JSON.stringify(r));
	debugEnabled && console.log(JSON.stringify(prod));

	const errorField = 'productId';
	var err = null;
	// Start evaluation
	if (!r.productStatus) {
		err = { priority: 3, message: 'product_is_inactive', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validateCartonLogistics(r, prod) {
	debugEnabled && console.log('================= validateCartonLogistics ==============');
	const errorField = 'cartonLogistics';
	var err = null;
	// Start evaluation
	if (r.cartonLogistics && r.cartonLogistics != null) {
		if (isNumber(r.cartonLogistics)) {
			var n = Number(r.cartonLogistics);
			if (n < 1 || n > 999) {
				err = { priority: 7, message: 'carton_logistics_out_of_range', field: errorField, type: 'E' };
			} else {
				if (prod && prod != null && n != prod.cartonLogistics) {
					return { message: 'carton_logistics_different_from_product_definition', field: errorField, type: 'W' };
				}
			}
		} else {
			err = { priority: 7, message: 'carton_logistics_must_be_number', field: errorField, type: 'E' };
		}
	} else {
		err = { priority: 7, message: 'missing_carton_logistics', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validatePalletLogistics(r, prod) {
	debugEnabled && console.log('================= validatePalletLogistics ==============');
	const errorField = 'palletLogistics';
	var err = null;
	// Start evaluation
	if (r.palletLogistics && r.palletLogistics != null) {
		if (isNumber(r.palletLogistics)) {
			var n = Number(r.palletLogistics);
			if (n < 1 || n > 999) {
				err = { priority: 7, message: 'pallet_logistics_out_of_range', field: errorField, type: 'E' };
			} else {
				if (prod && prod != null && n != prod.palletLogistics) {
					return { message: 'pallet_logistics_different_from_product_definition', field: errorField, type: 'W' };
				}
			}
		} else {
			err = { priority: 7, message: 'pallet_logistics_must_be_number', field: errorField, type: 'E' };
		}
	} else {
		err = { priority: 7, message: 'missing_pallet_logistics', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validateCountryOfOriginCode(r, countryOfOriginList) {
	debugEnabled && console.log('================= validateCountryOfOriginCode ==============');
	const errorField = 'countryOfOrigin';
	let err = null;

	// Start evaluation
	if (r.countryOfOrigin && r.countryOfOrigin != null) {
		// Split, trim, normalize
		const values = r.countryOfOrigin.split(',')
			.map(val => val.trim().toUpperCase());

		// Find invalid codes
		const invalidCodes = values.filter(val =>
			!countryOfOriginList.some(x => x.code.toUpperCase() === val)
		);

		if (values.length > 3) {
			// Too many values
			err = {
				priority: 6,
				message: `invalid_count_of_coo_codes`,
				field: errorField,
				type: 'E'
			};
		}

		if (invalidCodes.length === 0) {
			// All codes are valid
			r.countryOfOrigin = values
				.map(val => countryOfOriginList.find(x => x.code.toUpperCase() === val).code)
				.join(', ');
		} else {
			// Some codes are invalid
			err = {
				priority: 6,
				message: `invalid_country_of_origin_code`,
				field: errorField,
				type: 'E'
			};
		}
	} else {
		// Missing field error
		err = {
			priority: 6,
			message: 'missing_country_of_origin_code',
			field: errorField,
			type: 'E'
		};
	}

	debugEnabled && console.log({ err });
	return err;
}

export function validateCountryOfOriginNameToCode(r, countryOfOriginList) {
	debugEnabled && console.log('================= validateCountryOfOriginNameToCode ==============');
	const errorField = 'countryOfOrigin';
	var err = null;
	// Start evaluation
	if (r.countryOfOrigin && r.countryOfOrigin != null) {
		var coo = [...countryOfOriginList].filter(x => x.description.toUpperCase() == r.countryOfOrigin.toUpperCase());
		if (coo && coo != null && coo.length > 0) {
			r.countryOfOrigin = coo[0].code;
		} else {
			err = { priority: 6, message: 'invalid_country_of_origin_name', field: errorField, type: 'E' };
		}
	} else {
		err = { priority: 6, message: 'missing_country_of_origin_name', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validateVolume(r) {
	debugEnabled && console.log('================= validateVolume ==============');
	const errorField = 'volume';
	var err = null;
	// Start evaluation
	if (r.volume && r.volume != null) {
		if (isNumber(r.volume)) {
			var n = Number(r.volume);
			if (n < 1 || n > 10000000) {
				err = { priority: 4, message: 'volume_out_of_range', field: errorField, type: 'E' };
			}
		} else {
			err = { priority: 4, message: 'volume_must_be_number', field: errorField, type: 'E' };
		}
	} else {
		err = { priority: 4, message: 'missing_volume', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validateUnits(r, prod) {
	debugEnabled && console.log('================= validateUnits ==============');
	//console.log(prod);
	const errorField = 'units';
	var err = null;
	// Start evaluation
	if (r.units && r.units != null) {
		if (prod && prod != null && prod.units && prod.units != null && r.units.toLowerCase() != prod.units.toLowerCase()) {
			err = { priority: 5, message: 'units_different_from_product_definition', field: errorField, type: 'E' };
		}
	} else {
		err = { priority: 5, message: 'missing_units', field: errorField, type: 'E' };
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validateCurrency(r, prod, country) {
	debugEnabled && console.log('================= validateCurrency ==============');
	var cntry = country[0].toUpperCase() + country[1].toLowerCase(),
		errMsg,
		err = null;
	const errorField = 'currency' + cntry;
	//console.log(r.productId);
	//console.log({country});
	//console.log({cntry});
	//console.log({prod});
	errMsg = getFieldErrMsg(r, errorField, 'E');

	// Supplier is linked to the local product
	if (prod && prod['currency' + cntry] && prod['currency' + cntry] != null) {
		// Input currency exists
		if (r['currency' + cntry] && r['currency' + cntry] != null) {
			// Input currency doesn't match the supplier's defined
			if (r['currency' + cntry].toUpperCase() != prod['currency' + cntry].toUpperCase()) {
				if ((r['price' + cntry] && r['price' + cntry] != null) || (r['pricePromo' + cntry] && r['pricePromo' + cntry] != null)) {
					err = { priority: 8, message: 'currency_' + country.toLowerCase() + '_different_from_product_definition', field: errorField, type: 'E' };
				} else {
					err = { priority: 8, message: 'currency_' + country.toLowerCase() + '_different_from_product_definition', field: errorField, type: 'W' };
				}

			}
		} else {
			// Input currency is empty
			//err = {priority: 8, message: 'missing_'+country.toLowerCase()+'_currency', field: errorField, type:'E'};
		}
	} else {
		// Supplier is not linked to the local product, but input currency is not empty for the country
		if (r['currency' + cntry] && r['currency' + cntry] != null) {
			// Price and Promo Price are not empty, insert error msg
			if ((r['price' + cntry] && r['price' + cntry] != null) || (r['pricePromo' + cntry] && r['pricePromo' + cntry] != null)) {
				err = { priority: 8, message: 'invalid_' + country.toLowerCase() + '_currency_supplier_not_linked_to_local_product', field: errorField, type: 'E' };
			} else {
				// Price and Promo Price are empty -> return warning
				err = { message: 'invalid_' + country.toLowerCase() + '_currency_but_not_used', field: errorField, type: 'W' };
			}
		}
	}
	debugEnabled && console.log({ err });
	return err;
}

export function validatePrice(r, prod, country, priceType) {
	debugEnabled && console.log('================= validatePrice ==============');
	var cntry = country[0].toUpperCase() + country[1].toLowerCase(),
		promo = (priceType == 'PROMO') ? 'Promo' : '',
		countries = ['Cz', 'Sk', 'Hu'],
		linkedCountries = [],
		errMsg,
		err = null;
	const errorField = 'price' + promo + cntry;
	errMsg = getFieldErrMsg(r, errorField, 'E');
	//console.log({errMsg});

	// Get list of countries for which the supplier is linked on CE product
	countries.forEach(c => {
		if (prod && prod['currency' + c] && prod['currency' + c] != null) {
			linkedCountries.push(c.toUpperCase());
		}
	});

	// Supplier is linked to input country's local porduct
	if (linkedCountries.includes(country)) {
		// Input price is not empty
		if (r['price' + promo + cntry] !== null && r['price' + promo + cntry] !== undefined) {
			// Input price is Number
			if (isNumber(r['price' + promo + cntry])) {
				var n = Number(r['price' + promo + cntry]);
				// Input price is out of allowed range
				if ((r['currency' + cntry] == 'EUR' && (n < 0.01 || n > 50)) || (r['currency' + cntry] != 'EUR' && (n < 0.1 || n > 20000))) {
					err = { priority: 8, message: country.toLowerCase() + '_' + ((priceType == 'PROMO') ? 'promo_' : '') + 'price_out_of_range', field: errorField, type: 'E' };
				}
			} else {
				// Input price is not number
				err = { priority: 8, message: country.toLowerCase() + '_' + ((priceType == 'PROMO') ? 'promo_' : '') + 'price_must_be_number', field: errorField, type: 'E' };
			}
		}
	} else {
		// Supplier is not linked to input country's local product but not empty price exists on that country
		if (r['price' + promo + cntry] !== null && r['price' + promo + cntry] !== undefined) {
			err = { priority: 8, message: 'invalid_' + country.toLowerCase() + '_' + ((priceType == 'PROMO') ? 'promo_' : '') + 'price_supplier_not_linked_to_local_product', field: errorField, type: 'E' };
		}
	}

	debugEnabled && console.log({ err });
	return err;
}

export function validatePriceIsNotEmpty(r, prod) {
	debugEnabled && console.log('================= validatePriceIsNotEmpty ==============');
	const errorField = 'price';
	// Start evaluation
	var countries = ['Cz', 'Sk', 'Hu'],
		linkedCountries = [],
		found = false,
		err = null;

	// Get list of countries for which the supplier is linked on CE product
	countries.forEach(c => {
		if (prod && prod['currency' + c] && prod['currency' + c] != null) {
			linkedCountries.push(c);
		}
	});

	linkedCountries.forEach(c => {
		const hasPrice = r['price' + c] > 0;
		const hasPromoPrice = r['pricePromo' + c] > 0;

		if (hasPrice) {
			found = true;
		} else if (hasPromoPrice && !hasPrice) {
			// Promo price exists but price does not
			err = { priority: 4, message: 'promo_price_but_no_price', field: errorField, type: 'E' };
		}
	});

	if (!found && !err) {
		// No price is entered on row and no specific promo+price issue
		err = { priority: 4, message: 'no_price_is_entered_for_product', field: errorField, type: 'E' };
	}

	debugEnabled && console.log({ err });
	return err;
}

export async function validateQuoteSheetRow(r, prod) {
	debugEnabled && console.log('================= validateQuoteSheetRow ==============');
	var errors = [];
	// Cleanup previous error
	r.errors = [];
	r.warnings = [];
	r = clearError(r, 'supplierId');
	r = clearError(r, 'productId');

	// Check supplier and Product
	if (r.supplierId == null) errors.push({ priority: 2, message: 'invalid_supplier', field: 'supplierId', type: 'E' });
	if (r.product == null) errors.push({ priority: 3, message: 'product_not_found_or_missing_product_supplier_link', field: 'productId', type: 'E' });

	errors.push(validateSupplierStatus(r));
	errors.push(validateProductStatus(r, prod));

	for (let c of ['CZ', 'SK', 'HU']) {
		// Currency
		errors.push(validateCurrency(r, prod, c));
		// Price
		errors.push(validatePrice(r, prod, c, 'REGULAR'));
		// Promo Price
		errors.push(validatePrice(r, prod, c, 'PROMO'));
	}

	// Carton Logistics
	errors.push(validateCartonLogistics(r, prod));

	// Pallet Logistics
	errors.push(validatePalletLogistics(r, prod));

	// Country of Origin
	errors.push(validateCountryOfOriginCode(r, countryOfOriginList));

	// Units
	errors.push(validateUnits(r, prod));

	// Volume
	errors.push(validateVolume(r));

	// Check if quotesheet exists already
	errors.push((await validateQSExists(r)));

	return errors.filter(function (el) { return el != null; });
}

export const validateQSExists = async (r, qsItems) => {
	debugEnabled && console.log('================= validateQSExists ==============');
	if (!qsItems || !Array.isArray(qsItems)) {
		const res = await loadQuoteSheet(r.year, r.week, r.supplierId, r.productId, r.countryOfOrigin, r.variety, r.cartonLogistics, r.palletLogistics);
		if (res && res.length > 0) {
			// Quote Sheet exists already
			return { message: 'quote_sheet_exists_already', field: 'id', type: 'W', qsId: res[0].id };
		}
	} else {
		let qs = qsItems.filter((x) =>
			x.year == r.year &&
			x.week == r.week &&
			x.supplierId == r.supplierId &&
			x.productId == r.productId &&
			x.countryOfOrigin == r.countryOfOrigin &&
			x.variety == r.variety &&
			x.cartonLogistics == r.cartonLogistics &&
			x.palletLogistics == r.palletLogistics);
		if (qs && qs.length > 0 && qs[0].id) {
			// Quote Sheet exists already
			return { message: 'quote_sheet_exists_already', field: 'id', type: 'W', qsId: qs[0].id };
		}
	}
	return null;
}

export const validateUploadedQSData = async (data, selectedWeek) => {
	debugEnabled && console.log('================= validateUploadedQSData ==============');
	var qsData = [],
		supplierList = [],
		supplierProductList = [],
		supplierStatus,
		countries = [],
		supp = {},
		prod = {},
		oldSupplierId = null,
		errors = [];

	countries = getStoreVal(countryList);
	countryOfOriginList = [...countries].filter(e => e.active);

	// Add Year
	data.forEach(x => x.year = selectedWeek.tesco_year);

	// QS exists
	let qsItems = await checkQuoteSheetItems(data.map(x => {
		return {
			year: selectedWeek.tesco_year, week: x.week, supplierId: x.supplierId, productId: x.productId,
			countryOfOrigin: x.countryOfOrigin, variety: x.variety, cartonLogistics: x.cartonLogistics, palletLogistics: x.palletLogistics
		}
	}
	));

	// console.log('BACK in VALIDATE');
	// console.log({selectedWeek});
	// console.log({countries});
	// console.log({countryOfOriginList});
	// console.log({data});
	// console.log({qsItems});

	for (let r of [...data]) {
		debugEnabled && console.log({ r });
		errors = [];
		r.errors = [];
		r.warnings = [];
		r.selected = false;

		if (r.week != selectedWeek.tesco_week) {
			errors.push({ priority: 1, message: 'wrong_week_number', field: 'week', type: 'E' });
		}

		// Supplier
		if (r.supplierId != oldSupplierId) {
			debugEnabled && console.log('New Supplier...');
			supplierList = await loadSuppliers(r.supplierId);
			debugEnabled && console.log({ supplierList });
			supp = [...supplierList].filter(s => (s.id == r.supplierId)).at(0);
			debugEnabled && console.log({ supp });
			if (supp && supp != null) {
				oldSupplierId = r.supplierId;
				supplierStatus = supp.status;
				// Get all supplier items
				supplierProductList = await loadSupplierProducts(r.supplierId, null, 1);
				debugEnabled && console.log({ supplierProductList });
			} else {
				errors.push({ priority: 2, message: 'invalid_supplier', field: 'supplierId', type: 'E' });
			}

		}

		// Supplier
		if (supp && supp != null) {
			r.supplierName = supp.name;
			r.supplierStatus = supplierStatus;
			// Validate SupplierStatus
			errors.push(validateSupplierStatus(r));
		}

		// Product
		// console.log("Product");
		// console.log({r});
		if (r.productId && r.productId != null) {
			prod = [...supplierProductList].filter(s => (s.supplierId == r.supplierId && s.productId == r.productId)).at(0);
			debugEnabled && console.log({ ...prod });
			if (prod && prod != null) {
				r.productDesc = prod.productDescription;
				r.productCartonLogistics = prod.cartonLogistics;
				r.productPalletLogistics = prod.palletLogistics;
				r.productStatus = prod.status;
				//r.units = prod.units;
				r.productUnits = prod.units;
				r.subGroupCode = prod.subGroupCode;
				r.sectionDesc = prod.sectionDesc
				r.productCurrencyCz = prod.currencyCz;
				r.productCurrencySk = prod.currencySk;
				r.productCurrencyHu = prod.currencyHu;
				r.product = prod;
				r.itemPrivilage = prod.itemPrivilage;
				r.buyerDetails = prod.buyerDetails;

				// Validate Product Status
				errors.push(validateProductStatus(r, prod));

				// Validate at least one price is entered
				errors.push(validatePriceIsNotEmpty(r, prod));

				if (!r.itemPrivilage) {
					errors.push({ priority: 1, message: 'item_privilage_missing', field: 'productId', type: 'E' });
				}

				for (let c of ['CZ', 'SK', 'HU']) {
					// Currency
					errors.push(validateCurrency(r, prod, c));
					// Price
					errors.push(validatePrice(r, prod, c, 'REGULAR'));
					// Promo Price
					errors.push(validatePrice(r, prod, c, 'PROMO'));
				}

				// Carton Logistics
				errors.push(validateCartonLogistics(r, prod));

				// Pallet Logistics
				errors.push(validatePalletLogistics(r, prod));

				// Country of Origin
				errors.push(validateCountryOfOriginCode(r, countryOfOriginList));

				// Units
				errors.push(validateUnits(r, prod));

				// Volume
				errors.push(validateVolume(r));

				// Check if quotesheet exists already
				errors.push(await validateQSExists(r, qsItems));

			} else {
				debugEnabled && console.log("Product not found", r.productId);
				errors.push({ priority: 3, message: 'product_not_found_or_missing_product_supplier_link', field: 'productId', type: 'E' });
				r.product = null;
				r.productCartonLogistics = r.cartonLogistics;
				r.productPalletLogistics = r.palletLogistics;
				r.itemPrivilage = false;
			}

		}

		debugEnabled && console.log('Before: ', [...errors]);
		r.errorsOpen = false;
		r = updateErrors(r, r.errors, r.warnings, null, errors);
		debugEnabled && console.log('After: ', [...errors]);

		// When invalid currency because supplier is not linked to country, but price is not entered then delete the warning and the currency value too
		for (let cntr of ['Cz', 'Sk', 'Hu']) {
			if (existsError(r, 'currency' + cntr, 'W') && getFieldErrMsg(r, 'currency' + cntr, 'W') == 'invalid_' + cntr.toLowerCase() + '_currency_but_not_used') {
				clearError(r, 'currency' + cntr);
				r['currency' + cntr] = null;
			}
		}

		// When QuoteSheet already exists then update row ID to the existing one
		if (existsError(r, 'id', 'W') && (getFieldErrMsg(r, 'id', 'W') == 'quote_sheet_exists_already')) {
			r.id = [...r.warnings].filter(w => (w.field == 'id') && w.type == 'W' && w.message == 'quote_sheet_exists_already').at(0).qsId;
		}
		qsData.push(r);
	};

	debugEnabled && console.log({ ...qsData });
	return qsData;
}

// Check for Product - Country of Origin - Variety duplicity
export const duplicityCheckQSData = async (data) => {
	debugEnabled && console.log('================= duplicityCheckQSData ==============');
	debugEnabled && console.log({ data });
	let idsList = [...data].map(item => [item.productId, item.supplierId, item.countryOfOrigin, item.variety].join('|'));
	let duplicities = idsList.filter((item, index) => idsList.indexOf(item) !== index);
	debugEnabled && console.log({ duplicities });

	data.forEach(row => {
		if (duplicities.includes([row.productId, row.supplierId, row.countryOfOrigin, row.variety].join('|'))) {
			// Add error
			row.errors.push({ priority: 3, message: 'duplicate_product_in_file', field: 'productId', type: 'E' });
		} else {
			if (row.errors.map(x => x.message).includes('duplicate_product_in_file')) {
				// remove existing error
				row.errors.splice(row.errors.map(x => x.field).indexOf('productId'), 1);
			}
		}
	})
}

export const duplicityCheck = async (data) => {
	debugEnabled && console.log('================= duplicityCheck ==============');
	debugEnabled && console.log({ data });

	// Create an idsList
	let idsList = [...data]
		.map(item => [item.productId, item.supplierId, item.countryOfOrigin, item.variety].join('|'));

	// Duplicates in the idsList
	let duplicities = idsList.filter((item, index) => idsList.indexOf(item) !== index);
	debugEnabled && console.log({ duplicities });

	data.forEach(row => {
		// Ensure errors exists
		if (!Array.isArray(row.errors)) {
			row.errors = [];
		}

		// Key for the current row
		const rowKey = [row.productId, row.supplierId, row.countryOfOrigin, row.variety].join('|');
		const duplicityIndex = duplicities.indexOf(rowKey);
		const errorMessageIndex = row.errors.findIndex(error => error.message === 'duplicate_product_in_file');

		// Check for duplicity
		if (duplicityIndex !== -1) {
			// Duplicity found but no error message exists => add the error message
			if (errorMessageIndex === -1) {
				row.errors.push({ priority: 3, message: 'duplicate_product_in_file', field: 'productId', type: 'E' });
			}
		} else {
			// Duplicity not found and error message exists => remove the error message
			if (errorMessageIndex !== -1) {
				row.errors.splice(errorMessageIndex, 1);
			}
		}
	});
};
