<script lang="ts">

  import {
    getAutobuy as apiGetAutobuy,
    createAutobuy as apiCreateAutobuy,
    updateAutobuy as apiUpdateAutobuy,
    deleteAutobuy as apiDeleteAutobuy
  } from "../../../api/Autobuy";

  import {
    getProductSupplier as apiGetProductSupplier
  } from "../../../api/Product";

  import {  handleApiError } from "../lib/errorHandler";

  import { countries, deliveryTypes, forecastStatuses, rejectPurchaseReasons } from "../../../stores/AppConfig";

  import { snackbarSuccess, authenticatedUser
          } from "../../../stores/AppStatus";

  import Select, { Option } from '@smui/select';
  import Dialog, { Title, Content, Actions, InitialFocus } from '@smui/dialog';
  import Button, { Label } from '@smui/button';
  import IconButton, { Icon } from '@smui/icon-button';
  import DataTable, { Head, Body, Row, Cell, Label as TableLabel } from '@smui/data-table';
  import DTInputCell from '../common/DTInputCell.svelte';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import Textfield from '@smui/textfield';
  import Radio from '@smui/radio';
  import CircularProgress from '@smui/circular-progress';

  import { _ } from 'svelte-i18n';
  import Flex from 'svelte-flex';
  import { mdiPercent, mdiAlertOutline } from '@mdi/js';


  import SelectorCountry from "./../common/SelectorCountry.svelte";
  import SelectorSupplier from "./../common/SelectorSupplier.svelte";
  import SupplierSelector from "./../common/SupplierSelector.svelte";
  import PSAutobuySetupTable from "./PSAutobuySetupTable.svelte";
  import { autobuySetupList }  from "../../../stores/Items";

  import { createEventDispatcher } from 'svelte';

  // Temporary prototype variables

  // Base variables
  export let open;
  export let productId;
  export let country = 'CZ';
  export let headerColors = "tescoblue white-text";
  export let big = false;
  export let canUpdate = false;

  let selectedCountry;
  let selectedSupplier;
  let participation = 0;

  let suppliers = [];

  let working = false;
  let processingChanges = false;
  let prevCountry;

  const dispatch = createEventDispatcher();

  const getAutobuyData = (async () => {
      $autobuySetupList = await apiGetAutobuy(null, productId, null, 'AB');
      $autobuySetupList.forEach(x => {
      x.rowType = 'orig';
      x.changed = false;
    })
    // $autobuySetupList
  })

  const getProductSuppliers = (async () => {
    let ret = await apiGetProductSupplier(null, productId, null, null, null, null, null, null, 1);
    suppliers = ret.map(x => {return {id: x.supplierId, supplierName: x.supplierName, country: x.country}})
  })

  const loadData = (async () => {
    working = true;
    try {
      await getAutobuyData();
      await getProductSuppliers();

        console.log({$autobuySetupList});
        console.log({suppliers});

      if (suppliers && Array.isArray(suppliers) && suppliers.length > 0) {
        $autobuySetupList.forEach(a => {
          a.supplierName = suppliers.filter(s => s.id == a.supplierId).at(0)?.supplierName;
        })

        $autobuySetupList = $autobuySetupList;
      }
    } catch (error) {
      console.log({error});
      error.loc = "AutobuySetup:loadData";
      if (!handleApiError($_("get_data_failed"), error)) throw(error);
    }
    working = false;
  })

  const addSupplierAction = (() => {

    if (selectedSupplier && selectedSupplier?.id && $autobuySetupList.filter(x => x.country == country && x.supplierId == selectedSupplier.id).length == 0 ) {
      $autobuySetupList.push({
        id: selectedSupplier.id,
        productId: productId,
        country: country,
        supplierId: selectedSupplier.id,
        supplierName: selectedSupplier.supplierName,
        participation: 0,
        rowType: 'new',
        changed: true,
        type: 'AB'
      });

      $autobuySetupList = $autobuySetupList;
      canAddSupplier = false;
      dispatch('autobuySetupAdd')
    }

  })

  const saveChanges = (async () => {

    let promises = [];
    processingChanges = true;
    try {
      $autobuySetupList.forEach(x => {
        if (x.changed) {
          if (x.rowType == 'new') {
            promises.push(apiCreateAutobuy(x));
          } else if (x.rowType == 'delete') {
            promises.push(apiDeleteAutobuy(x.id));
          } else {
            promises.push(apiUpdateAutobuy(x));
          }
        }
      })

      await Promise.all(promises);
      processingChanges = false;
      dispatch('autobuySetupSave');

      $snackbarSuccess.text = $_("changes_saved");
      $snackbarSuccess.element && $snackbarSuccess.element.open();

    } catch (error) {
      console.log({error});
      error.loc = "AutobuySetup:saveChanges";
      if (!handleApiError($_("update_failed_plain"), error)) throw(error);
    }
  })

  const supplierAlreadyLinked = ((selectedSupplier, country) => {
    if (selectedSupplier) {
      if ([...$autobuySetupList].filter(x => x.country == country && x.supplierId == selectedSupplier.id).length == 0) {
        return false
      }
    }

    return true;
  });

  // Validate participations - sum has to be dividable by 100 without remainder
  const participationsAreValid = ((list) => {
    let sum = $autobuySetupList.filter(x => x.rowType != 'delete').map(x => Number(x.participation)).reduce((acc, val) => acc + val, 0);
    if ((sum % 100) > 0) {
      return false
    }

    return true;
  })

  const dataChanged = ((list) => {
    return ($autobuySetupList.filter(x => x.changed).length > 0);
  })

  $: country = (selectedCountry && selectedCountry?.id) ? selectedCountry?.id.toUpperCase() : null;

  $: if (open) {
    if (!selectedCountry) {
      selectedCountry = $countries.at(0);
    }
      if (suppliers.length == 0) {
        loadData();
      }
  } else {
    suppliers = [];
    selectedSupplier = null;
  }

  $: if (prevCountry != country) {
    selectedSupplier = null;
    prevCountry = country;
  }

  $: canAddSupplier = !supplierAlreadyLinked(selectedSupplier, country, $autobuySetupList);
  $: canSaveData = participationsAreValid($autobuySetupList) && dataChanged($autobuySetupList);

  $: {
        console.log('------- AUTOBUY SETUP POPUP ---------');
        console.log({$autobuySetupList});
        console.log({suppliers});
        console.log({$countries});
        console.log({selectedCountry});
        console.log({country});
        console.log({selectedSupplier});
        console.log({canAddSupplier});
        console.log({canSaveData});
    }


</script>


<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="min-height:500px; min-width: 700px; {big ? 'width: 1920px; max-width: calc(100vw - 32px);' : ''}"
>
<Title id="data-changed-title" class="{headerColors}">
  {$_('setup_autobuy')}
</Title>
<Content id="data-changed-content" class="mt-4">

  <Flex direction="row" justify="between" align="start" class=" gap-05 gray lighten-4 h-100 pt-2 mb-6">
    <Flex dirction="row" justify="start" class="gap-1">
      <!-- Country Selector -->
      <SelectorCountry
        bind:country={selectedCountry}
        countries={[...$countries]}
        />

      {#if (canUpdate)}
      <!-- Supplier Selector -->
        <SelectorSupplier
            bind:supplier={selectedSupplier}
            suppliers={[...suppliers].filter(c => c.country == country)}
        />
      {/if}
    </Flex>

      {#if (canUpdate)}
        <!-- Add Supplier Button  -->
        <Button
            class="{canAddSupplier ? 'tescoblue-text' : 'grey-text'} white-tescoblue-outlined pr-4 nowrap"
            style="min-width: 100px;"
            title="{$_('add_supplier')}"
            disabled={!canAddSupplier}
            on:click="{addSupplierAction}"
            >
            <Icon class="material-icons">add_circle</Icon>
            {$_('add_supplier')}
        </Button>
      {/if}
  </Flex>
  <hr>
  <div class="nowrap mt-3 tescoblue-text"><span class="font-weight-bold">{$_('suppliers_participations')}</span></div>
  <Flex direction="row" justify="between" align="center" class=" gap-05 w-100 gray lighten-4 h-100 pt-2">
    <!-- Autobuy Setup Table -->
      <PSAutobuySetupTable
        bind:items = {$autobuySetupList}
        {country}
        {working}
      />
  </Flex>

</Content>
<Flex direction="row" justify="end" class="pa-1 pb-2 pr-4">
  {#if (canUpdate)}
    <Button
      action="cancel"
      class="tescored tescored-text outlined"
      on:click="{() => dispatch('autobuySetupCancel')}">
      <Label>{$_('cancel')}</Label>
    </Button>
    <div title="{canSaveData ? '' : $_('participation_sum_error')}" class="pl-2 pr-1">
      <Button
        action="save"
        class="{canSaveData ? 'tescoblue' : 'grey'} white-text"
        disabled={!canSaveData}
        on:click="{saveChanges}">
        {#if (processingChanges)}
          <CircularProgress class="white-text" style="height: 1rem; width: 1rem; padding-right: 1rem" indeterminate />
        {/if}
        <Label>{$_('save')}</Label>
      </Button>
    </div>
  {:else}
    <Button
      action="cancel"
      class="tescoblue white-text"
      on:click="{() => dispatch('autobuySetupCancel')}">
      <Label>{$_('close')}</Label>
    </Button>
  {/if}
</Flex>
</Dialog>

<style>
</style>
