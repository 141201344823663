<script>
    import { getQuoteSheets as apiGetQuoteSheets } from "../../api/QuoteSheet";
    import { getForecast as apiGetForecast, updateForecast as apiUpdateForecast } from "../../api/Forecast";
    import {
        getPurchase as apiGetPurchase,
        createPurchase as apiCreatePurchase,
        savePurchase as apiSavePurchase,
        savePurchaseNew as apiSavePurchaseNew,
        updatePurchase as apiUpdatePurchase,
        deletePurchase as apiDeletePurchase,
        purchaseAutobuy as apiPurchaseAutobuy,
        getHistoricPrices as apiGetHistoricPrices,
    } from "../../api/Purchase";

    import { getProduct as apiGetProduct } from "../../api/Product";

    import { getAutobuy as apiGetAutobuy } from "../../api/Autobuy";

    import { handleApiError } from "./lib/errorHandler";

    import { onMount, createEventDispatcher } from "svelte";

    import WeekInfo from "./common/WeekInfo.svelte";
    import BackButton from "./common/BackButton.svelte";
    import ProductSelector from "./common/ProductSelector.svelte";
    import PSButtons from "./PurchaseScreen/PSButtons.svelte";
    import PSFilterBar from "./PurchaseScreen/PSFilterBar.svelte";
    import PSQuoteSheetsTable from "./PurchaseScreen/PSQuoteSheetsTable.svelte";
    import PSPurchaseTable from "./PurchaseScreen/PSPurchaseTable.svelte";
    import PSDifferencesTable from "./PurchaseScreen/PSDifferencesTable.svelte";
    import PSPurchasePopup from "./PurchaseScreen/PSPurchasePopup.svelte";
    import PSRejectForecastPopup from "./PurchaseScreen/PSRejectForecastPopup.svelte";
    import PSAutobuySetupPopup from "./PurchaseScreen/PSAutobuySetupPopup.svelte";
    import PSPurchaseFCStatusPopup from "./PurchaseScreen/PSPurchaseFCStatusPopup.svelte";
    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";
    import InfoDialog from "../dialogs/InfoDialog.svelte";
    import QuoteSheetCountryFilter from "./common/CountryFilterBar.svelte";
    import PurchaseCountryFilter from "./common/FullDCFilterBar.svelte";
    import FullscreenLoader from "../elementary/FullscreenLoader.svelte";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { selectedWeek, exchangeRates, snackbarSuccess, snackbarWarning, authenticatedUser } from "../../stores/AppStatus";

    import { countries, dcList, fullDcList } from "../../stores/AppConfig";

    import { weeks } from "../../stores/Weeks";

    import Paper from "@smui/paper";

    const dispatch = createEventDispatcher();

    export let selectedProduct = null;
    export let products = [];
    export let preselectedDCs;
    export let canInsert = false;
    export let canUpdate = false;
    export let canDelete = false;

    let uploadFiles;
    let countryCodes;

    // Purchase popup dialog
    let purchasePopupOpen = false;
    let purchasePopupType = "add";
    let purchasePopupScope = "dc";
    let purchasePopupData = null;
    let purchasePopupCountry = null;
    let purchasePopupProductId = null;
    let purchasePopupProductNameEn = null;
    let rejectPurchasePopupOpen = false;
    let rejectPurchasePopupData = null;
    let autobuySetupOpen = false;
    let autobuySetupData = null;
    let showDeletePurchaseConfirmationDialog = false;
    let showRejectPurchaseConfirmationDialog = false;
    let showAutobuyConfirmationDialog = false;
    let showConfirmPurchaseConfirmationDialog = false;
    let showUndoConfirmationConfirmationDialog = false;
    let undoConfirmationData = {};
    let showPurchaseFCStatusDialog = false;
    let quoteSheetCountrySearchArray = [];
    let purchaseDCSearchArray = [];
    
    let deleteErrDialogOpen = false;

    let prevQuoteSheetCountrySearch = "";

    let prevSelectedProductId = null;
    let loadingData = false;
    let loadingDataMessage = "";

    let qsRows = []; // Quote Sheet rows
    let fcRows = []; // Forecast rows
    let prRows = []; // Purchase rows
    let mergeRows = []; // Forecast and Purchase merged rows
    let diffRows = []; // Difference rows
    let autobuyRows = []; // Contains autobuy split
    let autofillRows = []; // Contains autofill split
    let historicPrices = new Map(); // Last Week and last Year prices
    let productData = {};

    let deletePurchaseId;
    let rejectPurchaseData;
    let newFCStatus;
    let selectedPurchaseDiffReason;
    let purchaseDiffComment = "";

    let disableConfirmButton = false;
    let enableAutobuyButton = true;

    onMount(() => {
        countryCodes = [
            ...$countries.map((x) => {
                return { id: x.id, code: x.id.toUpperCase(), str: x.id.toUpperCase()[0] + x.id.toLowerCase()[1] };
            }),
        ];
    });

    const convertToEur = (value, currency) => {
        let retValue = null;

        if (currency && value) {
            currency = String(currency).toUpperCase();
            if (currency == "EUR") {
                retValue = value;
            } else {
                let exRate = $exchangeRates.filter((x) => x.code == currency).at(0)?.valueInEur;
                // console.log(exRate);
                if (exRate && exRate > 0) {
                    retValue = (value / exRate).toFixed(3);
                }
            }
        }

        return retValue;
    };

    const clearProductsData = () => {
        prRows = [];
        fcRows = [];
        qsRows = [];
        diffRows = [];
        autobuyRows = [];
        autofillRows = [];
        historicPrices = new Map();
    };

    // Merge Forecast and Purchase rows
    const mergeData = (autobuyResult) => {
        console.log("== mergeData ==");
        console.log({ autobuyResult });

        mergeRows = [];
        fcRows.forEach((fc) => {
            if (autobuyResult) {
                let res = autobuyResult.filter((ab) => ab.forecastId == fc.forecastId).at(0);
                if (res) {
                    fc.autobuyStatus = res.result ? res.result : "";
                    fc.autobuyDetail = res?.detail ? res.detail : "";
                }
            }
            mergeRows.push(fc);
            prRows
                .filter((pr) => pr.forecastId == fc.forecastId)
                .forEach((r) => {
                    mergeRows.push({ ...r, descriptionEn: fc.descriptionEn });
                });
        });

        mergeRows = mergeRows;
        console.log({ mergeRows });
    };

    const updateQSRows = (type) => {
        qsRows.forEach((qs) => {
            qs.participation = autofillRows.filter((x) => x.country == qs.country && x.supplierId == qs.supplierId).at(0)?.participation || 0;
            qs.remainingVolume =
                qs.volume -
                prRows
                    .filter((x) => x.quoteSheetId == qs.qsId && x.productId == qs.productId && x.status != "rejected")
                    .reduce((acc, val) => acc + val.totalVol, 0);
            if (qs.remainingVolume < 0) {
                qs.remainingVolume = 0;
            }
            if (type == "init") {
                if (historicPrices.has("lw")) {
                    qs.priceLW = historicPrices
                        .get("lw")
                        .filter((x) => x.country == qs.country && x.supplierId == qs.supplierId)
                        .at(0)?.price;
                    qs.priceEurLW = historicPrices
                        .get("lw")
                        .filter((x) => x.country == qs.country && x.supplierId == qs.supplierId)
                        .at(0)?.priceEur;
                }
                if (historicPrices.has("ly")) {
                    qs.priceLY = historicPrices
                        .get("ly")
                        .filter((x) => x.country == qs.country && x.supplierId == qs.supplierId)
                        .at(0)?.price;
                    qs.priceEurLY = historicPrices
                        .get("ly")
                        .filter((x) => x.country == qs.country && x.supplierId == qs.supplierId)
                        .at(0)?.priceEur;
                }
            }
        });
        console.log("updateQSRows()", { autofillRows }, { qsRows }, { prRows });
        qsRows = qsRows;
    };

    // Compute last week or last year
    const getLast = (type) => {
        let last = { tesco_year: $selectedWeek.tesco_year, tesco_week: $selectedWeek.tesco_week };

        if (type == "W") {
            // Previous week
            last.tesco_week = last.tesco_week - 1;
            if (last.tesco_week < 1) {
                // use max week of previous year
                last.tesco_year = last.tesco_year - 1;
                last.tesco_week = $weeks
                    .filter((x) => x.tesco_year == last.tesco_year)
                    .map((x) => x.tesco_week)
                    .reduce((acc, val) => (acc = val > acc ? val : acc));
            }
        } else {
            // previous year
            // get week in last year that contains current week start date
            let lastDate = new Date(
                $selectedWeek.week_start.getFullYear() - 1,
                $selectedWeek.week_start.getMonth(),
                $selectedWeek.week_start.getDate(),
            );
            let lastWeek = $weeks.filter((x) => lastDate >= x.week_start && lastDate <= x.week_end).at(0);

            if (lastWeek) {
                last.tesco_year = lastWeek.tesco_year;
                last.tesco_week = lastWeek.tesco_week;
            } else {
                last.tesco_year = null;
                last.tesco_week = null;
            }
        }
        return last;
    };

    const loadProductData = async (autobuyResult) => {
        loadingData = true;
        clearProductsData();
        let lastWeek = getLast("W");
        let lastYear = getLast("Y");

        let promises = [loadProductQuoteSheets(), loadProductForecasts(), loadProductPurchases(), loadAutobuySetting(), getProductData()];

        if (lastWeek.tesco_year) {
            promises.push(loadHistoricPrices("lw", lastWeek.tesco_year, lastWeek.tesco_week, selectedProduct.productId));
        }

        if (lastYear.tesco_year) {
            promises.push(loadHistoricPrices("ly", lastYear.tesco_year, lastYear.tesco_week, selectedProduct.productId));
        }

        await Promise.all(promises);

        console.log({ promises });

        updateQSRows("init");

        mergeData(autobuyResult);
        loadingData = false;
    };

    const loadProductQuoteSheets = async () => {
        console.log("==== loadProductQuoteSheets() ====");
        try {
            let lastWeek = getLast("W");
            console.log({ lastWeek });

            let res, lw;
            let promises = [
                apiGetQuoteSheets(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week, null, null, selectedProduct.productId),
                apiGetPurchase(null, lastWeek.tesco_year, lastWeek.tesco_week, null, null, selectedProduct.productId),
            ];
            await Promise.all(promises).then((values) => {
                res = [...values.at(0)];
                lw = [...values.at(1)];
            });

            console.log({ promises });

            console.log("loadProductQuoteSheets(QS)", { res });
            console.log("loadProductQuoteSheets(Purch)", { lw });

            qsRows = [];
            let qsRow = {};
            let rowNo = 0;
            for (let row of res) {
                let lwRow = {};
                for (let country of countryCodes) {
                    if (row.hasOwnProperty("price" + country.str) && row["price" + country.str]) {
                        lwRow = lw.filter((l) => l.country == country?.code).at(0) || {};
                        console.log(country?.code, { ...lwRow });
                        qsRow = {
                            ...row,
                            qsId: row.id,
                            id: rowNo,
                            country: country.code,
                            currency: row["currency" + country.str],
                            price: row["price" + country.str],
                            priceEur: convertToEur(row["price" + country.str], row["currency" + country.str]),
                            pricePromo: row["pricePromo" + country.str],
                            pricePromoEur: convertToEur(row["pricePromo" + country.str], row["currency" + country.str]),
                            cartonLogistics:
                                row?.purchaseAdjustment?.filter((r) => r.country == country.code && r.logisticsValid).at(0)?.buyerCartonLogistics ||
                                row.cartonLogistics,
                            palletLogistics:
                                row?.purchaseAdjustment?.filter((r) => r.country == country.code && r.logisticsValid).at(0)?.buyerPalletLogistics ||
                                row.palletLogistics,
                            lwCartonLogistics: lwRow.cartonLogistics,
                            lwPalletLogistics: lwRow.palletLogistics,
                        };
                        rowNo++;
                        // console.log("rowNo: ", rowNo);
                        // console.log({row});
                        qsRows.push({ ...qsRow });
                    }
                }
            }

            qsRows.forEach((x) => {
                if (x.productCartonLogistics) {
                    x.cartonVolume = (x.volume / x.productCartonLogistics).toFixed(2);
                }

                if (x.productPalletLogistics) {
                    x.palletVolume = (x.volume / x.productPalletLogistics).toFixed(2);
                }
            });

            if (qsRows.length == 0) {
                console.log("loadProductQuoteSheets().empty");
            } else {
                // $divisionsList = [...divsArray];
                qsRows = qsRows;
                console.log({ qsRows });
            }
        } catch (error) {
            console.log("loadProductQuoteSheets().error");
            console.log({ error });
            error.loc = "loadProductQuoteSheets";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const loadProductForecasts = async () => {
        try {
            const res = await apiGetForecast(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week, null, null, selectedProduct.productId);

            console.log({ res });

            fcRows = [];
            for (let row of res) {
                // console.log({row});
                fcRows.push({
                    ...row,
                    totalAmendVol:
                        (row?.d1AmendVol || 0) +
                        (row?.d2AmendVol || 0) +
                        (row?.d3AmendVol || 0) +
                        (row?.d4AmendVol || 0) +
                        (row?.d5AmendVol || 0) +
                        (row?.d6AmendVol || 0) +
                        (row?.d7AmendVol || 0),
                    totalVol:
                        (row?.d1Vol || 0) +
                        (row?.d2Vol || 0) +
                        (row?.d3Vol || 0) +
                        (row?.d4Vol || 0) +
                        (row?.d5Vol || 0) +
                        (row?.d6Vol || 0) +
                        (row?.d7Vol || 0) +
                        (row?.flexVol || 0),
                    rowType: "FORECAST",
                    forecastId: row.id,
                    id: "f-" + row.id,
                    warehouseCode: $dcList.filter((x) => x.id == row.warehouseId).at(0)?.code,
                });
            }

            if (fcRows.length == 0) {
                console.log("loadProductForecasts().empty");
            } else {
                // $divisionsList = [...divsArray];
                fcRows = fcRows;
                console.log("loadProductForecasts()", { fcRows });
            }
        } catch (error) {
            console.log("loadProductForecasts().error");
            console.log({ error });
            error.loc = "loadProductForecasts";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const loadProductPurchases = async () => {
        try {
            const res = await apiGetPurchase(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week, null, null, selectedProduct.productId);

            console.log({ res });

            prRows = [];
            for (let row of res) {
                // console.log({row});
                prRows.push({
                    ...row,
                    totalVol: row.d1Vol + row.d2Vol + row.d3Vol + row.d4Vol + row.d5Vol + row.d6Vol + row.d7Vol + row.flexVol,
                    totalAmendVol:
                        row.d1AmendVol + row.d2AmendVol + row.d3AmendVol + row.d4AmendVol + row.d5AmendVol + row.d6AmendVol + row.d7AmendVol,
                    rowType: "PURCHASE",
                    deleted: row.status == "rejected",
                    purchaseId: row.id,
                    id: "p-" + row.id,
                    warehouseCode: $dcList.filter((x) => x.id == row.warehouseId).at(0)?.code,
                });
            }

            if (prRows.length == 0) {
                console.log("loadProductPurchases().empty");
            } else {
                // $divisionsList = [...divsArray];
                prRows = prRows;
                console.log({ prRows });
            }
        } catch (error) {
            console.log("loadProductPurchases().error");
            console.log({ error });
            error.loc = "loadProductPurchases";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const loadHistoricPrices = async (type, year, week, productId, supplierId) => {
        try {
            const res = await apiGetHistoricPrices(year, week, null, null, productId);
            console.log({ res });
            historicPrices.set(type, [...res]);
        } catch (e) {
            console.log("loadHistoricPrices().error");
            console.log({ error });
            error.loc = "loadHistoricPrices";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const loadAutobuySetting = async () => {
        try {
            let abRes = await apiGetAutobuy(null, selectedProduct.productId);

            if (abRes.length == 0) {
                console.log("loadAutobuySetting().empty");
            } else {
                console.log({ abRes });
                autobuyRows = [...abRes].filter(r => (r.type == 'AB'));
                autofillRows = [...abRes].filter(r => (r.type == 'AF'));
                console.log({ autobuyRows });
                console.log({ autofillRows });
            }
        } catch (error) {
            console.log({ error });
            error.loc = "loadAutobuySetting";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const getProductData = async () => {
        if (!selectedProduct.productId) {
            return;
        }

        console.log("getProductData()", selectedProduct.productId);
        try {
            let ret = await apiGetProduct(selectedProduct.productId);
            if (ret) {
                productData = ret.at(0);
            }
            console.log({ productData });
        } catch (error) {
            console.log({ error });
            error.loc = "getProductData";
            if (!handleApiError($_("get_data_failed"), error)) throw error;
        }
    };

    const updateQSRowsRemainingVolume = () => {
        qsRows.forEach((qs) => {
            let purchasedVolume = mergeRows
                .filter((p) => p.rowType == "PURCHASE" && p.supplierId == qs.supplierId && p.productId == qs.productId && p.status != "rejected")
                .reduce((total, val) => (total += val.totalVol), 0);
            qs.remainingVolume = qs.volume - purchasedVolume;
            if (qs.remainingVolume < 0) {
                qs.remainingVolume = 0;
            }
            // console.log("qs.volume: ", qs.volume, 'purchasedVolume: ', purchasedVolume);
        });
        console.log("updateQSRowsRemainingVolume()", { qsRows }, { mergeRows });
        qsRows = qsRows;
    };

    const prepareDMLData = (fcData, mergeRows, selectedWeekStatus) => {
        const dmlData = [];

        console.log("prepareDMLData mergeRows ", mergeRows);

        // Ensure "PURCHASE" rows are processed before "FORECAST" rows -> sort
        mergeRows.sort((a, b) => (a.rowType === "PURCHASE" && b.rowType !== "PURCHASE" ? -1 : 1));

        // Group by forecastId
        mergeRows.forEach((row) => {
            // DTInputCell is providing string instead of number
            row.d1Vol = Number(row.d1Vol);
            row.d2Vol = Number(row.d2Vol);
            row.d3Vol = Number(row.d3Vol);
            row.d4Vol = Number(row.d4Vol);
            row.d5Vol = Number(row.d5Vol);
            row.d6Vol = Number(row.d6Vol);
            row.d7Vol = Number(row.d7Vol);
            row.flexVol = Number(row.flexVol);
            row.cartonLogistics = Number(row.cartonLogistics);
            row.palletLogistics = Number(row.palletLogistics);
            row.participation = Number(row.participation);

            let forecastOperations = dmlData.find((item) => item.forecastId === row.forecastId);

            // Only valid operation
            if (!forecastOperations) {
                forecastOperations = { forecastId: row.forecastId, operations: [] };
                dmlData.push(forecastOperations);
            }

            // Prepare row data
            const data = { ...row, rowId: row.id, id: row.purchaseId };

            // Actions based on rowType and rowState
            if (row.rowType === "PURCHASE" && fcData && fcData.data.forecastId === row.forecastId) {
                if (row.rowState === "add" && !row.deleted) {
                    addToOperation(forecastOperations, "createPurchase", data);
                } else if (row.rowState === "updated" && !row.deleted) {
                    addToOperation(forecastOperations, "updatePurchase", data);
                } else if (row.rowState !== "add" && row.deleted) {
                    if (selectedWeekStatus === "purchased" || row.status === "rejected") {
                        row.status = "rejected";
                        data.status = row.status;
                        addToOperation(forecastOperations, "updatePurchase", data);
                    } else {
                        addToOperation(forecastOperations, "deletePurchase", data.id);
                    }
                }
            } else if (row.rowType === "FORECAST" && fcData && fcData.data.forecastId === row.forecastId) {
                // Check if all remaining purchases are rejected
                if (allRemainingPurchasesRejected(row.forecastId, -1)) {
                    fcData.status = "rejected";
                }

                // Forecast update operation
                const forecastData = {
                    id: fcData.data.forecastId,
                    status: fcData.status,
                    reasonCode: fcData.reasonCode,
                    userComment: fcData.userComment,
                };
                addToOperation(forecastOperations, "updateForecast", forecastData);
            }
        });

        // Filter out any forecast operations that have no operations
        return dmlData.filter((forecast) => forecast.operations.length > 0);
    };

    // Helper function to add data to the appropriate operation
    const addToOperation = (forecastOperations, operationType, data) => {
        let operation = forecastOperations.operations.find((op) => op.operation === operationType);

        if (!operation) {
            operation = { operation: operationType, data: [] };
            forecastOperations.operations.push(operation);
        }

        operation.data.push(data);
    };

    const savePurchaseChangesNew = async (fcData) => {
        console.log("savePurchaseChanges fcData: ", { fcData });
        console.log("savePurchaseChanges mergeRows: ", { mergeRows });

        const newData = prepareDMLData(fcData, mergeRows, $selectedWeek.status);
        console.log("newData: ", { newData });
        if (newData) {
            loadingData = true;
            console.log("Start to Save Purchases", { newData });
            apiSavePurchaseNew(newData)
                .then((response) => {
                    console.log("Purchase changes saved successfully:", response.data);

                    // Update mergeRows with response data
                    response.data.forEach((r) => {
                        mergeRows.forEach((x) => {
                            if (r.type === "purchase" && x.rowType === "PURCHASE" && x.id === r.rowId) {
                                // Update purchase fields in mergeRows
                                if (!x.purchaseId) {
                                    x.purchaseId = r.id;
                                    x.id = "p-" + x.purchaseId;
                                }
                                x.rowState = "origin";
                            }

                            if (r.type === "forecast" && x.rowType === "FORECAST" && x.forecastId === r.id) {
                                // Update forecast fields in mergeRows
                                x.status = fcData.status;
                                x.reasonCode = fcData.reasonCode;
                                x.userComment = fcData.userComment;
                                x.purchaseConfirmed = "N";
                                x.autobuyStatus = "";
                                x.autobuyStatusMessage = "";

                                // Dispatch update forecast status to buying screen
                                dispatch("updateForecastStatus", {
                                    id: r.id,
                                    status: fcData.status,
                                    reasonCode: fcData.reasonCode,
                                    userComment: fcData.userComment,
                                    purchaseConfirmed: "N",
                                    autobuyStatus: "",
                                    autobuyStatusMessage: "",
                                    purchasedVol: mergeRows
                                        .filter((p) => p.rowType === "PURCHASE" && p.forecastId === r.id && p.status !== "rejected")
                                        .reduce((total, val) => (total += val.totalVol), 0),
                                });
                            }
                        });
                    });

                    // Trigger reactivity
                    mergeRows = mergeRows.filter((x) => !x?.deleted || (x?.deleted && x.status == "rejected"));

                    updateQSRowsRemainingVolume();

                    loadingData = false;
                    if (!$snackbarSuccess.element.isOpen()) {
                        $snackbarSuccess.text = $_("purchase_saved");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    }
                })
                .catch((error) => {
                    console.log("savePurchaseChanges error: ", error);
                    loadingData = false;
                    error.loc = "savePurchaseChanges";
                    if (!handleApiError($_("save_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            console.log("Purchases: no changes"); //TODO
        }

        /*        if (mergeRows.length > 0) {
            loadingData = true;
            console.log("Start to Save Purchases", { fcData }, { mergeRows });
            apiSavePurchase(fcData, mergeRows)
                .then((response) => {
                    console.log("Purchase changes saved successfully:", response.data);

                    // Update mergeRows with response data
                    response.data.forEach((r) => {
                        mergeRows.forEach((x) => {
                            if (r.type === "purchase" && x.rowType === "PURCHASE" && x.id === r.rowId) {
                                // Update purchase fields in mergeRows
                                if (!x.purchaseId) {
                                    x.purchaseId = r.id;
                                    x.id = "p-" + x.purchaseId;
                                }
                                x.rowState = "origin";
                            }

                            if (r.type === "forecast" && x.rowType === "FORECAST" && x.forecastId === r.id) {
                                // Update forecast fields in mergeRows
                                x.status = fcData.status;
                                x.reasonCode = fcData.reasonCode;
                                x.userComment = fcData.userComment;
                                x.purchaseConfirmed = "N";
                                x.autobuyStatus = "";
                                x.autobuyStatusMessage = "";

                                // Dispatch update forecast status to buying screen
                                dispatch("updateForecastStatus", {
                                    id: r.id,
                                    status: fcData.status,
                                    reasonCode: fcData.reasonCode,
                                    userComment: fcData.userComment,
                                    purchaseConfirmed: "N",
                                    autobuyStatus: "",
                                    autobuyStatusMessage: "",
                                    purchasedVol: mergeRows
                                        .filter((p) => p.rowType === "PURCHASE" && p.forecastId === r.id && p.status !== "rejected")
                                        .reduce((total, val) => (total += val.totalVol), 0),
                                });
                            }
                        });
                    });

                    // Trigger reactivity
                    mergeRows = mergeRows.filter((x) => !x?.deleted || (x?.deleted && x.status == "rejected"));

                    updateQSRowsRemainingVolume();

                    loadingData = false;
                    if (!$snackbarSuccess.element.isOpen()) {
                        $snackbarSuccess.text = $_("purchase_saved");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    }
                })
                .catch((error) => {
                    console.log("savePurchaseChanges error: ", error);
                    loadingData = false;
                    error.loc = "savePurchaseChanges";
                    if (!handleApiError($_("save_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            console.log("Purchases: no changes"); //TODO
        } */
    };

    const deletePurchase = async (purchaseId, forecastData) => {
        try {
            loadingData = true;
            let res = await apiDeletePurchase(purchaseId);
            let resFc = await apiUpdateForecast(forecastData);
            console.log({ res }, { resFc });

            mergeRows = mergeRows.filter((r) => r.purchaseId != purchaseId);

            mergeRows.forEach((r) => {
                if (r.rowType == "FORECAST" && r.forecastId == resFc.id) {
                    r.status = forecastData.status;
                    r.reasonCode = forecastData.reasonCode;
                    r.userComment = forecastData.userComment;
                    r.autobuyStatus = "";
                    r.autobuyStatusMessage = "";
                    r.purchasedVol = mergeRows
                        .filter((p) => p.rowType == "PURCHASE" && p.forecastId == resFc.id && p.status != "rejected")
                        .reduce((total, val) => (total += val.totalVol), 0);
                }
            });

            dispatch("updateForecastStatus", {
                id: resFc.id,
                status: forecastData.status,
                reasonCode: forecastData.reasonCode,
                userComment: forecastData.userComment,
                purchaseConfirmed: "N",
                autobuyStatus: "",
                autobuyStatusMessage: "",
                purchasedVol: mergeRows
                    .filter((p) => p.rowType == "PURCHASE" && p.forecastId == resFc.id && p.status != "rejected")
                    .reduce((total, val) => (total += val.totalVol), 0),
            });

            updateQSRowsRemainingVolume();
            loadingData = false;
            if (!$snackbarSuccess.element.isOpen()) {
                $snackbarSuccess.text = $_("purchase_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            }
        } catch (error) {
            console.log(error);
            loadingData = false;
            error.loc = "deletePurchase";
            if (!handleApiError($_("delete_failed"), error, "non-fatal")) throw error;
        }
    };

    const rejectForecast = async (data, reasonCode, userComment) => {
        console.log("rejectForecast()");
        console.log({ data }, { reasonCode }, { userComment });
        try {
            let res = await apiUpdateForecast({
                id: data.forecastId,
                year: data.year,
                week: data.week,
                country: data.country,
                productId: data.productId,
                productDetailId: data.productDetailId,
                tpnb: data.tpnb,
                warehouseId: data.warehouseId,
                status: "rejected",
                reasonCode: reasonCode,
                userComment: userComment,
            });
            console.log({ res });

            mergeRows.forEach((r) => {
                if (r.rowType == "FORECAST" && r.forecastId == data.forecastId) {
                    r.status = "rejected";
                    r.reasonCode = reasonCode;
                    r.userComment = userComment;
                    r.autobuyStatus = "";
                    r.autobuyStatusMessage = "";
                    r.purchasedVol = 0;
                }
            });

            dispatch("updateForecastStatus", {
                id: data.forecastId,
                status: "rejected",
                reasonCode: reasonCode,
                userComment: userComment,
                purchaseConfirmed: "Y",
                autobuyStatus: "",
                autobuyStatusMessage: "",
                purchasedVol: 0,
            });

            mergeRows = mergeRows;
            updateQSRowsRemainingVolume();
            if (!$snackbarSuccess.element.isOpen()) {
                $snackbarSuccess.text = $_("forecast_rejected");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            }
        } catch (error) {
            console.log(error);
            error.loc = "rejectForecast";
            if (!handleApiError($_("update_failed_plain"), error, "non-fatal")) throw error;
        }
    };

    // Note: with data.type set to "undoreject" you can undo rejection
    const rejectSupplierPurchase = async (data, fcStatus, reasonCode, userComment) => {
        console.log("rejectSupplierPurchase()");
        console.log({ data }, { reasonCode }, { userComment });

        try {
            if (fcStatus) {
                let res = await apiUpdateForecast({
                    id: data.forecastId,
                    year: data.year,
                    week: data.week,
                    country: data.country,
                    productId: data.productId,
                    productDetailId: data.productDetailId,
                    tpnb: data.tpnb,
                    warehouseId: data.warehouseId,
                    status: fcStatus,
                    reasonCode: reasonCode,
                    userComment: userComment,
                });
                console.log({ res });

                mergeRows.forEach((r) => {
                    if (r.rowType == "FORECAST" && r.forecastId == data.forecastId) {
                        r.status = fcStatus;
                        r.reasonCode = reasonCode;
                        r.userComment = userComment;
                        r.autobuyStatus = "";
                        r.autobuyStatusMessage = "";
                        r.purchaseConfirmed = data.type.startsWith("undo") ? "N" : r.purchaseConfirmed;
                        r.purchasedVol = mergeRows
                            .filter(
                                (p) =>
                                    p.rowType == "PURCHASE" &&
                                    p.forecastId == data.forecastId &&
                                    r.purchaseId != data.purchaseId &&
                                    p.status != "rejected",
                            )
                            .reduce((total, val) => (total += val.totalVol), 0);
                    }
                });
            }

            let purchData = {
                ...data,
                status: !data?.type || data?.type.startsWith("reject") ? "rejected" : "prepared",
                rowId: data.id,
                id: data.purchaseId,
            };
            let purchRes = await apiUpdatePurchase(purchData);
            console.log({ purchRes });

            mergeRows.forEach((r) => {
                if (r.rowType == "PURCHASE" && r.purchaseId == data.purchaseId) {
                    r.status = !data?.type || data?.type.startsWith("reject") ? "rejected" : "prepared";
                    r.deleted = r.status == "rejected";
                }
            });

            if (fcStatus) {
                dispatch("updateForecastStatus", {
                    id: data.forecastId,
                    status: fcStatus,
                    reasonCode: reasonCode,
                    userComment: userComment,
                    autobuyStatus: "",
                    autobuyStatusMessage: "",
                    purchaseConfirmed: data.type.startsWith("undo") ? "N" : "Y",
                    purchasedVol: mergeRows
                        .filter((p) => p.rowType == "PURCHASE" && p.forecastId == data.forecastId && p.status != "rejected")
                        .reduce((total, val) => (total += val.totalVol), 0),
                });
            }

            mergeRows = mergeRows;
            updateQSRowsRemainingVolume();
            if (!$snackbarSuccess.element.isOpen()) {
                $snackbarSuccess.text = $_("purchase_rejected");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            }
        } catch (error) {
            console.log(error);
            error.loc = "rejectSupplierPurchase";
            if (!handleApiError($_("update_failed_plain"), error, "non-fatal")) throw error;
        }
    };
    /*
    const updateForecastStatus = async (data, status, reasonCode, userComment) => {
        console.log("updateForecastStatus()");
        console.log({ data }, { reasonCode }, { userComment });
        try {
            let res = await apiUpdateForecast({
                id: data.forecastId,
                year: data.year,
                week: data.week,
                country: data.country,
                productId: data.productId,
                productDetailId: data.productDetailId,
                tpnb: data.tpnb,
                warehouseId: data.warehouseId,
                status: status,
                reasonCode: reasonCode,
                userComment: userComment,
            });
            console.log({ res });
            mergeRows.forEach((r) => {
                if (r.id == data.id) {
                    r.status = "rejected";
                    r.reasonCode = reasonCode;
                    r.userComment = userComment;
                    (r.autobuyStatus = ""),
                        (r.autobuyStatusMessage = ""),
                        (r.purchasedVol = mergeRows
                            .filter((p) => p.rowType == "PURCHASE" && p.forecastId == data.forecastId && p.status != "rejected")
                            .reduce((total, val) => (total += val.totalVol), 0));
                }
            });
            mergeRows = mergeRows;
            dispatch("updateForecastStatus", {
                id: data.forecastId,
                status: status,
                reasonCode: reasonCode,
                userComment: userComment,
                autobuyStatus: "",
                autobuyStatusMessage: "",
                purchasedVol: mergeRows
                    .filter((p) => p.rowType == "PURCHASE" && p.forecastId == data.forecastId && p.status != "rejected")
                    .reduce((total, val) => (total += val.totalVol), 0),
            });
            updateQSRowsRemainingVolume();
            if (!$snackbarSuccess.element.isOpen()) {
                $snackbarSuccess.text = $_("forecast_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            }
        } catch (error) {
            console.log(error);
            error.loc = "updateForecastStatus";
            if (!handleApiError($_("update_failed_plain"), error, "non-fatal")) throw error;
        }
    };
 */
    function showPurchasePopup(type, data, scope) {
        purchasePopupType = type;
        purchasePopupData = data;
        purchasePopupScope = scope ? scope : "dc";
        purchasePopupOpen = true;
    }

    function showDeletePurchasePopup(purchaseId) {
        deletePurchaseId = purchaseId;
        showDeletePurchaseConfirmationDialog = true;
    }

    function showRejectPurchasePopup(data) {
        rejectPurchaseData = data;
        showRejectPurchaseConfirmationDialog = true;
    }

    function handleShowPurchaseFCStatusDialog() {
        console.log("handleShowPurchaseFCStatusDialog()");
        console.log({ deletePurchaseId });

        showDeletePurchaseConfirmationDialog = false;
        let purchaseRow = mergeRows.filter((x) => x.rowType == "PURCHASE" && x.purchaseId == deletePurchaseId).at(0);
        let forecastRow = mergeRows.filter((x) => x.rowType == "FORECAST" && x.forecastId == purchaseRow.forecastId).at(0);

        // All purchases linked to the forecastID row
        let remainingPurchaseRows = mergeRows.filter(
            (x) => x.rowType == "PURCHASE" && x.forecastId == forecastRow.forecastId && x.purchaseId !== deletePurchaseId,
        );
        let totalPurchaseVol = remainingPurchaseRows.reduce((sum, row) => sum + row.totalVol, 0);

        // New status based on the FC total volume
        if (remainingPurchaseRows.length === 0) {
            // No purchases left, set status to "new"
            newFCStatus = "new";
        } else if (totalPurchaseVol < forecastRow.totalVol) {
            newFCStatus = "purchased_less";
        } else if (totalPurchaseVol === forecastRow.totalVol) {
            newFCStatus = "purchased";
        } else {
            newFCStatus = "purchased_more";
        }

        // Show the dialog if not purchasing less or more
        if (remainingPurchaseRows.length !== 0 && (newFCStatus == "purchased_less" || newFCStatus == "purchased_more") ) {
            showPurchaseFCStatusDialog = true;
        } else {
            // No dialog needed
            deletePurchase(deletePurchaseId, {
                id: forecastRow.forecastId,
                year: forecastRow.year,
                week: forecastRow.week,
                country: forecastRow.country,
                productId: forecastRow.productId,
                productDetailId: forecastRow.productDetailId,
                tpnb: forecastRow.tpnb,
                warehouseId: forecastRow.warehouseId,
                status: newFCStatus,
                reasonCode: null,
                userComment: "",
            });
            deletePurchaseId = null;
        }

        updateQSRowsRemainingVolume();
    }

    const handleFcReasonDialogConfirmed = (event) => {
        let purchaseRow = mergeRows.filter((x) => x.rowType == "PURCHASE" && x.purchaseId == deletePurchaseId).at(0);
        let forecastRow = mergeRows.filter((x) => x.rowType == "FORECAST" && x.forecastId == purchaseRow.forecastId).at(0);

        deletePurchase(deletePurchaseId, {
            id: forecastRow.forecastId,
            year: forecastRow.year,
            week: forecastRow.week,
            country: forecastRow.country,
            productId: forecastRow.productId,
            productDetailId: forecastRow.productDetailId,
            tpnb: forecastRow.tpnb,
            warehouseId: forecastRow.warehouseId,
            status: newFCStatus,
            reasonCode: selectedPurchaseDiffReason,
            userComment: purchaseDiffComment,
        });
    };

    const allRemainingPurchasesRejected = (fcId, purchaseId) => {
        // console.log("allPurchasesRejected("+fcId+", "+purchaseId+")");
        let rowStatuses = [
            ...new Set(mergeRows.filter((r) => r.rowType == "PURCHASE" && r.forecastId == fcId && r.purchaseId != purchaseId).map((x) => x.status)),
        ];
        console.log({ rowStatuses });

        if (rowStatuses.length > 1 || (rowStatuses.length == 1 && rowStatuses.at(0) != "rejected")) {
            // console.log("allPurchasesRejected: false");
            return false;
        }
        // console.log("allPurchasesRejected: true");
        return true;
    };

    const handleRejectSupplierPurchaseConfirmed = async () => {
        console.log("===handleRejectSupplierPurchaseConfirmed()===");
        rejectPurchaseData.type = "reject";

        // Compute new FC status
        let fcRow = mergeRows.filter((x) => x.rowType == "FORECAST" && x.forecastId == rejectPurchaseData.forecastId).at(0);
        let purchaseTotal = mergeRows
            .filter((x) => x.rowType == "PURCHASE" && x.forecastId == rejectPurchaseData.forecastId && x.purchaseId != rejectPurchaseData.purchaseId)
            .reduce((acc, x) => acc + x.totalVol, 0);

        let newFcStatus = "purchased";
        if (allRemainingPurchasesRejected(rejectPurchaseData.forecastId, rejectPurchaseData.purchaseId)) {
            newFcStatus = "rejected";
        } else {
            if (purchaseTotal > fcRow.totalVol) newFcStatus = "purchased_more";
            if (purchaseTotal < fcRow.totalVol) newFcStatus = "purchased_less";
        }

        console.log("old: ", fcRow.status, ", new: ", newFcStatus);

        if (newFcStatus == fcRow.status) {
            rejectSupplierPurchase(rejectPurchaseData, newFcStatus, null, "");
            return;
        }

        if (fcRow.status == "purchased_more" && newFcStatus == "purchased") {
            rejectSupplierPurchase(rejectPurchaseData, newFcStatus, null, "");
            return;
        }

        rejectPurchasePopupOpen = true;
        rejectPurchasePopupData = { ...rejectPurchaseData, rejectType: "purchase", fcStatus: newFcStatus, type: "reject" };
        // console.log("REJECT: ", {rejectPurchasePopupData});
    };

    const handleUndoRejectSupplierPurchase = async (row) => {
        console.log("=== handleUndoRejectSupplierPurchase() ===");
        console.log({ row });
        row.type = "undoreject";

        let fcRow = mergeRows.filter((x) => x.rowType == "FORECAST" && x.forecastId == row.forecastId).at(0);
        let purchaseTotal = mergeRows
            .filter((x) => x.rowType == "PURCHASE" && x.forecastId == row.forecastId && (x.status != "rejected" || x.purchaseId == row.purchaseId))
            .reduce((acc, x) => acc + x.totalVol, 0);
        console.log({ purchaseTotal });

        let newFcStatus = "purchased";
        if (purchaseTotal > fcRow.totalVol) newFcStatus = "purchased_more";
        if (purchaseTotal < fcRow.totalVol) newFcStatus = "purchased_less";

        console.log("old: ", fcRow.status, ", new: ", newFcStatus);

        if (newFcStatus == fcRow.status) {
            rejectSupplierPurchase(row, newFcStatus, null, "");
            return;
        }

        if (fcRow.status == "purchased_less" && newFcStatus == "purchased") {
            rejectSupplierPurchase(row, newFcStatus, null, "");
            return;
        }

        rejectPurchasePopupOpen = true;
        rejectPurchasePopupData = { ...row, rejectType: "purchase", fcStatus: newFcStatus, type: "undoreject" };
        // console.log("UNDO: ", {rejectPurchasePopupData});
    };

    const handlePurchaseConfirm = async () => {
        showConfirmPurchaseConfirmationDialog = false;

        let promises = [];
        let updatedFcRows = mergeRows.filter((x) => x.rowType == "FORECAST" && x.status == "updated").map((x) => x.forecastId);

        mergeRows.forEach((r) => {
            if (
                r.rowType == "PURCHASE" &&
                r.status == "prepared" &&
                (r.canBuy == "Y" || $authenticatedUser.isAdmin) &&
                !updatedFcRows.includes(r.forecastId)
            ) {
                let data = { ...r, rowId: r.id, id: r.purchaseId, status: "confirmed" };
                promises.push(apiUpdatePurchase(data));
            }
        });

        if (promises.length > 0) {
            loadingData = true;
            console.log("Confirm Purchases");
            try {
                let ret = await Promise.all(promises);

                mergeRows.forEach((r) => {
                    if (r.rowType == "PURCHASE" && r.status == "prepared" && (r.canBuy == "Y" || $authenticatedUser.isAdmin)) {
                        r.status = "confirmed";
                    }
                    if (r.rowType == "FORECAST" && (r.canBuy == "Y" || $authenticatedUser.isAdmin)) {
                        r.purchaseConfirmed = "Y";
                    }
                });

                mergeRows = mergeRows;

                dispatch("confirmItem", { item: selectedProduct });
                loadingData = false;
                if (!$snackbarSuccess.element.isOpen()) {
                    $snackbarSuccess.text = $_("confirmed");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                }
            } catch (error) {
                console.log(error);
                loadingData = false;
                if (!handleApiError($_("confirmation_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            }
        } else {
            $snackbarWarning.text = $_("nothing_to_confirm");
            $snackbarWarning.element && $snackbarWarning.element.open();
            console.log("Purchases: no changes");
        }
    };

    const handleUndoConfirm = async (fcData) => {
        console.log("=== handleUndoConfirm() ===");
        console.log({ fcData });

        let promises = [];
        mergeRows.forEach((r) => {
            if (
                r.rowType == "PURCHASE" &&
                r.forecastId == fcData.forecastId &&
                r.status == "confirmed" &&
                (r.canBuy == "Y" || $authenticatedUser.isAdmin)
            ) {
                let data = { ...r, rowId: r.id, id: r.purchaseId, status: "prepared" };
                promises.push(apiUpdatePurchase(data));
            }
        });

        if (promises.length > 0) {
            loadingData = true;
            console.log("Undo Confirm Purchases");
            try {
                let ret = await Promise.all(promises);

                mergeRows.forEach((r) => {
                    if (
                        r.rowType == "PURCHASE" &&
                        r.forecastId == fcData.forecastId &&
                        r.status == "confirmed" &&
                        (r.canBuy == "Y" || $authenticatedUser.isAdmin)
                    ) {
                        r.status = "prepared";
                    }
                    if (r.rowType == "FORECAST" && (r.canBuy == "Y" || $authenticatedUser.isAdmin)) {
                        r.purchaseConfirmed = "N";
                    }
                });

                mergeRows = mergeRows;

                dispatch("updateForecastStatus", {
                    id: fcData.forecastId,
                    status: fcData.status,
                    reasonCode: fcData.reasonCode,
                    userComment: fcData.userComment,
                    purchaseConfirmed: "N",
                    autobuyStatus: "",
                    autobuyStatusMessage: "",
                    purchasedVol: mergeRows
                        .filter((p) => p.rowType == "PURCHASE" && p.forecastId == fcData.forecastId && p.status != "rejected")
                        .reduce((total, val) => (total += val.totalVol), 0),
                });

                loadingData = false;
                updateQSRowsRemainingVolume();
                if (!$snackbarSuccess.element.isOpen()) {
                    $snackbarSuccess.text = $_("confirmation_revoked");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                }
            } catch (error) {
                console.log(error);
                loadingData = false;
                if (!handleApiError($_("undo_confirmation_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            }
        } else {
            $snackbarWarning.text = $_("nothing_to_confirm");
            $snackbarWarning.element && $snackbarWarning.element.open();
            console.log("Purchases: no changes");
        }
    };

    const handlePurchaseEvents = async (event) => {
        console.log("handlePurchaseEvents");
        console.log({ event });

        switch (event.type) {
            case "newPurchase":
                console.log("New Purchase");
                showPurchasePopup("add", event.detail);
                break;
            case "newCountryPurchase":
                console.log("New Country Purchase");
                showPurchasePopup("add", event.detail, "country");
                break;
            case "editPurchase":
                console.log("Edit Purchase");
                showPurchasePopup("edit", event.detail);
                break;
            case "deletePurchase":
                console.log("Delete Purchase");
                if (event.detail.amendmentExists == "Y") {
                    deleteErrDialogOpen = true;
                } else {
                    showDeletePurchasePopup(event.detail.purchaseId);
                }
                break;
            case "autobuyPurchase":
                console.log("Autobuy Purchase");
                showAutobuyConfirmationDialog = true;
                break;
            case "autobuySetup":
                console.log("Setup Autobuy");
                autobuySetupOpen = true;
                break;
            case "autobuySetupAdd":
                console.log("Setup Autobuy Add Supplier");
                break;
            case "autobuySetupDelete":
                console.log("Setup Autobuy Delete");
                break;
            case "autobuySetupCancel":
                console.log("Setup Autobuy Cancel");
                autobuySetupOpen = false;
                break;
            case "autobuySetupSave":
                console.log("Setup Autobuy Save");
                autobuySetupOpen = false;
                await loadAutobuySetting();
                updateQSRows();
                break;
            case "autofillSetupSave":
                console.log("Autofill Save");
                await loadAutobuySetting();
                updateQSRows();
                break;
            case "savePurchase":
                console.log("Save Purchase");
                break;
            case "confirmPurchase":
                console.log("Confirm Purchase");
                showConfirmPurchaseConfirmationDialog = true;
                break;
            case "undoConfirmation":
                console.log("Undo Confirmation");
                showUndoConfirmationConfirmationDialog = true;
                undoConfirmationData = event.detail;
                break;
            case "purchasePopupAutofill":
                console.log("Purchase popup Autofill", { event });
                handleAutofill(event.detail);
                break;
            case "purchasePopupCancel":
                console.log("Purchase popup Cancel");
                purchasePopupOpen = false;
                break;
            case "purchasePopupSave":
                console.log("Purchase popup Save");
                console.log({ mergeRows });
                //savePurchaseChanges(event.detail);
                savePurchaseChangesNew(event.detail);
                purchasePopupOpen = false;
                break;
            case "rejectPurchase":
                console.log("Reject Purchase");
                rejectPurchasePopupOpen = true;
                rejectPurchasePopupData = event.detail;
                rejectPurchasePopupData.fcStatus = "rejected";
                break;
            case "rejectSupplierPurchase":
                console.log("Reject Supplier Purchase");
                rejectPurchaseData = event.detail;
                showRejectPurchaseConfirmationDialog = true;
                break;
            case "undoRejectSupplierPurchase":
                console.log("Undo Reject Supplier Purchase");
                handleUndoRejectSupplierPurchase(event.detail);
                break;
            case "rejectPurchasePopupCancel":
                console.log("Reject Forecast Purchase Popup CANCEL");
                rejectPurchasePopupOpen = false;
                break;
            case "rejectPurchasePopupSave":
                console.log("Reject Forecast Purchase Popup SAVE");
                rejectPurchasePopupOpen = false;
                if (rejectPurchasePopupData.rejectType == "purchase") {
                    rejectSupplierPurchase(rejectPurchasePopupData, rejectPurchasePopupData.fcStatus, event.detail.reason, event.detail.comment);
                } else {
                    rejectForecast(rejectPurchasePopupData, event.detail.reason, event.detail.comment);
                }
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const handleQSPriceUpdate = async (e) => {
        console.log("handleQSPriceUpdate()", { e });

        let detail = e.detail;

        console.log({ detail });

        qsRows.forEach((row) => {
            if (row.id == detail.item.id) {
                if (detail.priceType == "normal") {
                    row.price = detail.item["price" + detail.country];
                    row.priceEur = convertToEur(detail.item["price" + detail.country], detail.item["currency" + detail.country]);
                } else if (detail.priceType == "promo") {
                    row.pricePromo = detail.item["pricePromo" + detail.country];
                    row.pricePromoEur = convertToEur(detail.item["pricePromo" + detail.country], detail.item["currency" + detail.country]);
                }
            }
        });

        qsRows = qsRows;
    };

    const handleQSRowUpdate = async (e) => {
        console.log("handleQSRowUpdate()", { e });

        let detail = e.detail;

        console.log({ detail });

        qsRows.forEach((row) => {
            if (row.id == detail.item.id) {
                row.variety = detail.item.variety;
                row.countryOfOrigin = detail.item.countryOfOrigin;
                row.cartonLogistics = detail.item.cartonLogistics;
                row.palletLogistics = detail.item.palletLogistics;
                row["price" + detail.country] = Number(detail.item["price" + detail.country]);
                row["pricePromo" + detail.country] = detail.item["pricePromo" + detail.country]
                    ? Number(detail.item["pricePromo" + detail.country])
                    : "";
            }
        });

        // qsRows.forEach(row => {
        //     if (row.id == detail.item.id) {
        //         if (detail.priceType == 'normal') {
        //             row.price = detail.item["price"+detail.country];
        //             row.priceEur = convertToEur(detail.item["price"+detail.country], detail.item['currency'+detail.country]);
        //         } else if (detail.priceType == 'promo') {
        //             row.pricePromo = detail.item["pricePromo"+detail.country];
        //             row.pricePromoEur = convertToEur(detail.item["pricePromo"+detail.country], detail.item['currency'+detail.country]);
        //         }
        //     }
        // })

        qsRows = qsRows;
    };

    const handleAutobuy = async (e) => {
        console.log("================= handleAutobuy ==============");
        let rows = [...mergeRows]
            .filter(
                (r) =>
                    r.rowType == "FORECAST" &&
                    (r.canBuy == "Y" || $authenticatedUser.isAdmin) &&
                    r.autobuy == 1 &&
                    r.purchasedVol == 0 &&
                    ["new", "updated"].includes(r.status),
            )
            .map((r) => {
                return { year: r.year, week: r.week, country: r.country, forecastId: r.forecastId };
            });

        if (rows.length > 0) {
            //workingText = 'autobuy_please_wait';
            //workingStatus = 'working';
            loadingData = true;
            loadingDataMessage = $_("processing_autobuy_please_wait");
            apiPurchaseAutobuy(rows)
                .then(async (response) => {
                    //workingText = '';
                    //workingStatus = '';
                    console.log({ response });
                    if (response && response.length > 0) {
                        await loadProductData(response);
                        let fcOK = response.filter((x) => x.result == "OK").map((x) => x.forecastId);
                        let fcErr = response.filter((x) => x.result == "ERROR").map((x) => x.forecastId);

                        fcOK.forEach((fcId) => {
                            dispatch("updateForecastStatus", {
                                id: fcId,
                                status: "purchased",
                                autobuyStatus: "OK",
                                reasonCode: "",
                                userComment: "",
                                purchasedVol: mergeRows
                                    .filter((p) => p.rowType == "PURCHASE" && p.forecastId == fcId && p.status != "rejected")
                                    .reduce((total, val) => (total += val.totalVol), 0),
                            });
                        });

                        fcErr.forEach((fcId) => {
                            dispatch("updateForecastStatus", {
                                id: fcId,
                                autobuyStatus: "ERROR",
                                autobuyStatusMessage: response.filter((x) => x.forecastId == fcId).at(0)?.detail,
                                reasonCode: "",
                                userComment: "",
                            });
                        });

                        if (fcOK.length > 0) {
                            if (fcErr.length > 0) {
                                $snackbarWarning.text = $_("autobuy_finished_with_issues");
                                $snackbarWarning.element && $snackbarWarning.element.open();
                            } else {
                                if (!$snackbarSuccess.element.isOpen()) {
                                    $snackbarSuccess.text = $_("autobuy_finished");
                                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                                }
                            }
                        } else {
                            $snackbarWarning.text = $_("autobuy_finished_nothing_bought");
                            $snackbarWarning.element && $snackbarWarning.element.open();
                        }
                    }
                    loadingData = false;
                    loadingDataMessage = $_("");
                })
                .catch((error) => {
                    //workingText = '';
                    //workingStatus = '';
                    loadingDataMessage = $_("");
                    if (!handleApiError($_("autobuy_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            $snackbarWarning.text = $_("found_no_suitable_rows_for_autobuy");
            $snackbarWarning.element && $snackbarWarning.element.open();
        }
    };


    const updatePurchaseDCSearchArray = (country, checked) => {
        // console.log("=== updatePurchaseDCSearchArray ===");
        // console.log({country}, {checked});
        if (checked) {
            purchaseDCSearchArray = [
                ...purchaseDCSearchArray.filter((x) => x.country != country),
                ...$fullDcList.filter((x) => x.type == "LOCAL" && x.country == country),
            ];
        }
    };

    $: if (selectedProduct && selectedProduct.productId != prevSelectedProductId) {
        loadProductData();

        // Preselect countries filter
        let preselectedCountries = [...new Set(preselectedDCs.map((x) => x?.substring(0, 2)))];
        if (preselectedCountries && quoteSheetCountrySearchArray.length == 0) {
            quoteSheetCountrySearchArray = [...$countries.filter((x) => preselectedCountries.includes(x.id.toUpperCase()))];
        }

        // Preselect DCs filter
        if (preselectedDCs.length > 0) {
            purchaseDCSearchArray = [...$fullDcList.filter((x) => x.type == "LOCAL" && preselectedDCs.includes(x.code))];
        }

        prevSelectedProductId = selectedProduct.productId;
    }

    $: if (quoteSheetCountrySearchArray.map((x) => x.id).join("-") != prevQuoteSheetCountrySearch) {
        // console.log("QS filter array changed");
        // console.log({prevQuoteSheetCountrySearch});
        // purchaseDCSearchArray = quoteSheetCountrySearchArray;
        prevQuoteSheetCountrySearch = quoteSheetCountrySearchArray.map((x) => x.id).join("-");
    }

    /* Disable confirmation button when:
     *  1) incorrect week status
     *  2) All forecasts have purchase confirmed
     *  3) Some forecast is not confirmed but status is not in purchased, purchased_less or purchased_more
     */
    $: disableConfirmButton =
        !canUpdate ||
        !mergeRows
            .filter((x) => x.rowType == "FORECAST")
            .map((x) => x.purchaseConfirmed)
            .includes("N") ||
        mergeRows.filter(
            (x) =>
                x.rowType == "FORECAST" &&
                (x.canBuy == "Y" || $authenticatedUser.isAdmin) &&
                x.purchaseConfirmed == "N" &&
                (x.status.startsWith("purchased") || x.status == "updated"),
        ).length == 0;

    $: enableAutobuyButton =
        canUpdate &&
        mergeRows.filter(
            (x) =>
                x.rowType == "FORECAST" &&
                (x.canBuy == "Y" || $authenticatedUser.isAdmin) &&
                ["new", "updated"].includes(x.status) &&
                x.autobuy == 1 &&
                x.purchasedVol == 0,
        ).length > 0 &&
        autobuyRows.length > 0;

    $: {
        console.log("---------- PURCHASE SCREEN -----------");
        console.log({ selectedProduct });
        console.log({ $selectedWeek });
        console.log({ preselectedDCs });
        console.log({ qsRows });
        console.log({ prRows });
        console.log({ mergeRows });

        // console.log("---------- ...... -----------");
        // console.log({disableConfirmButton});
        // console.log({canUpdate});
        // console.log("All FC confirmed", (!mergeRows.filter(x => x.rowType=='FORECAST').map(x => x.purchaseConfirmed).includes('N')));
        // console.log("Some not confirmed", (mergeRows.filter(x => x.rowType=='FORECAST' && x.purchaseConfirmed == 'N' && (x.status.startsWith('purchased') || x.status == 'updated')).length == 0));

        // console.log({countryCodes});
        // console.log({quoteSheetCountrySearchArray});
        // console.log({purchaseDCSearchArray});
        // console.log({$fullDcList});
    }
</script>

<section class="purchase main-data-view">
    <section class="w-100 header top-splitter">
        <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pl-1 pr-1 gap-0">
            <BackButton on:goBack />
            <WeekInfo disabled={true} on:weekStatusChanged />
            <!-- <div class="nowrap"><span class="font-weight-bold">{$_('product')}:</span> {'20091123456 Lemon'}</div> -->
            <ProductSelector bind:selectedProduct {products} />
            <Flex direction="row" justify="between" class="gap-1 w-100">
                <Flex direction="row" justify="end" class="w-100 gap-1">
                    <PSButtons
                        {disableConfirmButton}
                        disableAutobuyButton={!enableAutobuyButton}
                        on:autobuyPurchase={handlePurchaseEvents}
                        on:autobuySetup={handlePurchaseEvents}
                        on:savePurchase={handlePurchaseEvents}
                        on:confirmPurchase={handlePurchaseEvents}
                    />
                </Flex>
            </Flex>
        </Flex>
    </section>

    <section class="data tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb mb-10">
                <Flex direction="row" justify="start" class="nowrap mt-3"
                    ><span class="font-weight-bold mr-3">{$_("supplier_table")}:</span>
                    <QuoteSheetCountryFilter
                        bind:selection={quoteSheetCountrySearchArray}
                        title={$_("country")}
                        size="10"
                        multiselect
                        on:change={(e) => {
                            updatePurchaseDCSearchArray(e?.detail?.data?.id?.toUpperCase(), e?.detail?.checked);
                        }}
                    />
                </Flex>
                <PSQuoteSheetsTable
                    bind:items={qsRows}
                    product={productData}
                    filter={quoteSheetCountrySearchArray.map((x) => x.id.toUpperCase())}
                    hideBuyIcon={true}
                    hideEditIcon={true}
                    editable={["open", "forecasted"].includes($selectedWeek?.status)}
                    on:qsPriceUpdated={handleQSPriceUpdate}
                />

                <Flex direction="row" justify="start" class="nowrap mt-3"
                    ><span class="font-weight-bold mr-3">{$_("purchase_table")}:</span>
                    <PurchaseCountryFilter bind:selection={purchaseDCSearchArray} title={$_("dc")} size="20" multiselect />
                </Flex>
                <PSPurchaseTable
                    items={mergeRows}
                    filter={$fullDcList
                        .filter(
                            (dc) =>
                                dc.type == "LOCAL" &&
                                ((purchaseDCSearchArray.length > 0 && purchaseDCSearchArray.map((x) => x.code).includes(dc.code)) ||
                                    (purchaseDCSearchArray.length == 0 &&
                                        quoteSheetCountrySearchArray.map((x) => x.id.toUpperCase()).includes(dc.country))),
                        )
                        .map((x) => x.code)}
                    {canInsert}
                    {canUpdate}
                    {canDelete}
                    on:editPurchase={handlePurchaseEvents}
                    on:deletePurchase={handlePurchaseEvents}
                    on:rejectPurchase={handlePurchaseEvents}
                    on:newPurchase={handlePurchaseEvents}
                    on:newCountryPurchase={handlePurchaseEvents}
                    on:rejectSupplierPurchase={handlePurchaseEvents}
                    on:undoConfirmation={handlePurchaseEvents}
                    on:undoRejectSupplierPurchase={handlePurchaseEvents}
                />
                <div class="nowrap mt-3"><span class="font-weight-bold">{$_("difference_table")}</span></div>
                <PSDifferencesTable rows={diffRows} />
            </div>
        </Paper>
    </section>
</section>

<FullscreenLoader open={loadingData} text={loadingDataMessage} />

<PSPurchasePopup
    open={purchasePopupOpen}
    type={purchasePopupType}
    scope={purchasePopupScope}
    title={purchasePopupType == "add" ? $_("purchase") : $_("edit_purchase")}
    data={purchasePopupData}
    {qsRows}
    autofillData={[...autofillRows].filter(r => (r.country == purchasePopupData?.country))}
    bind:mergeRows
    on:purchasePopupAutofill={handlePurchaseEvents}
    on:purchasePopupCancel={handlePurchaseEvents}
    on:purchasePopupSave={handlePurchaseEvents}
    on:qsRowUpdated={handleQSRowUpdate}
    on:autofillSetupSave={handlePurchaseEvents}
></PSPurchasePopup>

<PSRejectForecastPopup
    bind:open={rejectPurchasePopupOpen}
    data={rejectPurchasePopupData}
    on:rejectPurchasePopupCancel={handlePurchaseEvents}
    on:rejectPurchasePopupSave={handlePurchaseEvents}
></PSRejectForecastPopup>

<PSAutobuySetupPopup
    bind:open={autobuySetupOpen}
    bind:data={autobuySetupData}
    productId={selectedProduct.productId}
    {canUpdate}
    on:autobuySetupAdd={handlePurchaseEvents}
    on:autobuySetupDelete={handlePurchaseEvents}
    on:autobuySetupCancel={handlePurchaseEvents}
    on:autobuySetupSave={handlePurchaseEvents}
></PSAutobuySetupPopup>

<ConfirmationDialog
    open={showDeletePurchaseConfirmationDialog}
    title={$_("delete_purchase")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        deletePurchaseId = null;
        showDeletePurchaseConfirmationDialog = false;
    }}
    on:confirm={() => {
        handleShowPurchaseFCStatusDialog();
    }}
>
    {$_("delete_purchase?")}
</ConfirmationDialog>

<ConfirmationDialog
    bind:open={showRejectPurchaseConfirmationDialog}
    title={$_("reject_purchase")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        deletePurchaseId = null;
    }}
    on:confirm={() => {
        handleRejectSupplierPurchaseConfirmed();
    }}
>
    {$_("reject_purchase?")}
</ConfirmationDialog>

<ConfirmationDialog
    open={showAutobuyConfirmationDialog}
    title={$_("autobuy")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showAutobuyConfirmationDialog = false;
    }}
    on:confirm={() => {
        handleAutobuy();
        showAutobuyConfirmationDialog = false;
    }}
>
    {$_("run_autobuy?")}
</ConfirmationDialog>

<ConfirmationDialog
    open={showConfirmPurchaseConfirmationDialog}
    title={$_("confirm_purchase")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmPurchaseConfirmationDialog = false;
    }}
    on:confirm={handlePurchaseConfirm}
>
    {$_("confirm_purchase?")}
</ConfirmationDialog>

<ConfirmationDialog
    bind:open={showUndoConfirmationConfirmationDialog}
    title={$_("undo_confirmation")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showUndoConfirmationConfirmationDialog = false;
    }}
    on:confirm={() => {
        handleUndoConfirm(undoConfirmationData);
    }}
>
    {$_("undo_confirmation?")}
</ConfirmationDialog>

<PSPurchaseFCStatusPopup
    bind:open={showPurchaseFCStatusDialog}
    insideDialog={false}
    fcStatus={newFCStatus}
    bind:selectedPurchaseDiffReason
    bind:purchaseDiffComment
    on:cancelled
    on:confirmed={handleFcReasonDialogConfirmed}
/>

<InfoDialog
    open={deleteErrDialogOpen}
    title={$_("cant_delete")}
    btnText={$_("close")}
    big={false}
    headerColors="tescored white-text"
    on:close={() => {
        deleteErrDialogOpen = false;
    }}
>
    {@html $_("amendment_exists")}
</InfoDialog>

<style>
</style>
